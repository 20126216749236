import React from "react";
import "./tassets/totalcare.css";
import clogo from "../assets/clogo.png";
import abatherapy from "./tassets/abatherapy.jpg";
import ba from "./tassets/ba.jpg";
import ip from "./tassets/ip.jpg";
import dca from "./tassets/dca.jpg";
import gam from "./tassets/gam.jpg";
import Page from "./Page";
import play from "./tassets/play.jpg";
import adl from "./tassets/adl.jpg";
import Header from "./header";
import Footer from "./footer";
import Fade from "react-reveal/Fade";
import Zoom from "react-reveal/Zoom";

function Aba() {
  return (
    <>
        <Page title="Behavior Therapy (ABA)" />

      <Header />
      <div className="servicediv">
      <Fade left delay={700}>
        <h4 className="oth">Behavior Therapy (ABA)</h4>
        <br></br> <br></br>
      </Fade>
      <div class="otit">
        <div className="otpicdiv">
          <Zoom delay={500}>
            <img className="otpic" src={abatherapy} alt="behavior therapy (aba)"></img>
          </Zoom>
        </div>
        <div class="text-small ottext ">
          <Fade bottom delay={300}>
          Behavior therapy is a psychological approach focused on modifying and improving individuals’ behaviors by identifying patterns, setting goals, and implementing strategies. It aims to address issues such as phobias, anxiety, and addiction through techniques like reinforcement, conditioning, and cognitive restructuring, promoting positive, lasting change in behavior and emotional well-being.
            </Fade>
          
        </div>
      </div>
      <br></br>
      <div class="section less-padding">
      <div class="container-default">
        <h2 class="title about-story">Our Services in Behavior Therapy (ABA)</h2>
        <div class="services-cards">
          <div class="services-card"><img
              src={ba}
              
              sizes="(max-width: 479px) 100vw, (max-width: 767px) 75vw, (max-width: 991px) 46vw, (max-width: 1439px) 30vw, 380px"
              
              alt="Behavior Analysis" class="services-card-img" />
            <div class="services-card-content">
              <h2 class="services-card-title">Behavior Analysis</h2>
              <p class="services-card-subtitle">A scientific approach that studies and modifies behavior through understanding environmental factors, fostering positive change, and promoting learning.<br /></p>
            </div>
          </div>
          <div class="services-card"><img
              src={ip}
              
              sizes="(max-width: 479px) 100vw, (max-width: 767px) 75vw, (max-width: 991px) 46vw, (max-width: 1439px) 30vw, 380px"
             
              alt="Intervention Planning" class="services-card-img" />
            <div class="services-card-content">
              <h2 class="services-card-title">Intervention Planning</h2>
              <p class="services-card-subtitle">A strategic process to design and implement actions aimed at addressing specific needs or goals, guiding positive change.<br /></p>
            </div>
          </div>
          <div class="services-card"><img
              src={dca}
              
              sizes="(max-width: 479px) 100vw, (max-width: 767px) 75vw, (max-width: 991px) 46vw, (max-width: 1439px) 30vw, 380px"
              
              alt="Data Collection and Analysis" class="services-card-img" />
            <div class="services-card-content">
              <h2 class="services-card-title">Data Collection and Analysis</h2>
              <p class="services-card-subtitle">The process of gathering information and examining it to extract meaningful insights and make informed decisions.<br /></p>
            </div>
          </div>
          <div class="services-card gam"><img
              src={gam}
              
              sizes="(max-width: 479px) 100vw, (max-width: 767px) 75vw, (max-width: 991px) 46vw, (max-width: 1439px) 30vw, 380px"
              
              alt="Generalization and Maintenance" class="services-card-img" />
            <div class="services-card-content">
              <h2 class="services-card-title">Generalization and Maintenance</h2>
              <p class="services-card-subtitle">In learning and therapy, the ability to apply newly acquired skills or behavior across various situations and sustain them over time.<br /></p>
            </div>
          </div>
         
        </div>
      </div>
      </div>
      <br></br>
      <br></br>
      <br></br> <br></br>
      <br></br>
      <br></br>
      </div>
      <Footer />
    </>
  );
}

export default Aba;
