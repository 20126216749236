import React from "react";
import "./tassets/totalcare.css";
import clogo from "../assets/clogo.png";
import picky from "./tassets/picky.jpg";
import Header from "./header";
import Footer from "./footer";
import Fade from "react-reveal/Fade";
import Zoom from "react-reveal/Zoom";

function Pickyeater() {
  return (
    <>
      <Header />
      <Fade left delay={700}>
        <h4 className="oth">Picky Eaters</h4>
        <br></br> <br></br>
      </Fade>
      <div class="otit">
        <div className="otpicdiv">
          <Zoom delay={500}>
            <img className="otpic" src={picky}></img>
          </Zoom>
        </div>
        <div class="text-small ottext ">
          <Fade bottom delay={300}>
            A "picky eater" is a term commonly used to describe individuals,
            often children, who have a limited food repertoire and are reluctant
            to try new foods. Picky eating behaviors can vary in severity and
            may be influenced by various factors, including sensory
            sensitivities, food aversions, developmental stages, and learned
            behaviors.
            <br></br>
            Here are some strategies to help address picky eating behaviors:
            <br></br>
            <b>1. Create a Positive Mealtime Environment: </b>
            Establish a relaxed and positive atmosphere during mealtimes. Avoid
            pressuring or forcing the individual to eat, as this can increase
            resistance. Encourage pleasant and social interactions during meals,
            and make the experience enjoyable.
            <br></br>
            <b> 2. Offer a Variety of Foods: </b>
            Introduce a wide range of foods, including different textures,
            flavors, and colors. Gradually expose the individual to new foods
            and flavors, and provide opportunities for exploration and tasting.
            Offer a variety of healthy options and be patient with the
            individual's acceptance of new foods.
          </Fade>
        </div>
      </div>
      <br></br>
      <div class="text-small ottext2 ">
        <Zoom>
          <b>3. Encourage Involvement in Food Preparation </b>
          Involve the individual in meal planning, grocery shopping, and food
          preparation. Engaging in these activities can increase familiarity and
          interest in food, and may make them more willing to try new foods.
          <br></br> <b>4. Provide Role Modeling: </b>
          Be a positive role model by demonstrating healthy eating behaviors.
          Show enthusiasm for trying new foods and express enjoyment of a varied
          diet. When the individual sees others enjoying a food, they may be
          more inclined to try it themselves.
          <br></br>
        </Zoom>
        <Zoom>
          <b>5. Start with Small Steps: </b>
          Gradually introduce new foods or variations of familiar foods. Begin
          with small portions or incorporate new ingredients into familiar
          dishes. Gradual exposure can help individuals become more comfortable
          with new flavors and textures.
          <br></br>
          <b> 6. Offer Food Choices and Autonomy: </b> Provide limited choices
          to give the individual a sense of control over their meals. Offer two
          or three options, ensuring that all choices are nutritious. This
          approach can empower the individual and encourage their active
          participation in mealtime decisions.
          <br></br>
          <b>7. Incorporate Foods in a Familiar Context: </b>
          Introduce new foods in a familiar and preferred context. For example,
          if the individual enjoys smoothies, consider adding a small amount of
          a new fruit or vegetable to their favorite smoothie recipe. This can
          help them become accustomed to the taste and texture in a more
          familiar and accepted form.
          <br></br>
        </Zoom>
        <Zoom>
          <b>8. Gradual Food Exposure: </b>
          Use a gradual exposure approach by progressively increasing exposure
          to new foods. This can involve placing the new food on the plate
          without any expectation of eating it initially, gradually moving
          toward smelling, touching, and eventually tasting the food.
          <br></br>
          <b>9. Seek Professional Guidance: </b>
          If picky eating behaviors persist and significantly impact the
          individual's nutrition and overall well-being, it may be beneficial to
          consult with a healthcare professional, such as a pediatrician,
          dietitian, or feeding therapist. These professionals can provide
          guidance and support tailored to the individual's specific needs.
          <br></br>
          Remember, every individual is unique, and what works for one picky
          eater may not work for another. It's important to be patient,
          understanding, and flexible in addressing picky eating behaviors. With
          consistent support and exposure to a variety of foods, many picky
          eaters can gradually expand their food preferences and develop a more
          balanced diet over time.
        </Zoom>
      </div>
      <br></br>
      <br></br>
      <br></br> <br></br>
      <br></br>
      <br></br>
      <Footer />
    </>
  );
}

export default Pickyeater;
