import React from "react";
import "./tassets/totalcare.css";
import clogo from "../assets/clogo.png";
import feedtherapy from "./tassets/feedtherapy.jpg";
import Header from "./header";
import Footer from "./footer";
import Fade from "react-reveal/Fade";
import Zoom from "react-reveal/Zoom";

function Feedtherapy() {
  return (
    <>
      <Header />
      <Fade left delay={700}>
        <h4 className="oth">Feeding Therapy</h4>
        <br></br> <br></br>
      </Fade>
      <div class="otit">
        <div className="otpicdiv">
          <Zoom delay={500}>
            <img className="otpic" src={feedtherapy}></img>
          </Zoom>
        </div>
        <div class="text-small ottext ">
          <Fade bottom delay={300}>
            Feeding therapy, also known as pediatric feeding therapy or
            pediatric feeding intervention, is a specialized form of therapy
            aimed at addressing difficulties related to feeding and eating in
            infants, children, and adolescents. Feeding therapy is designed to
            improve oral motor skills, expand food acceptance, address aversions
            or sensory issues related to food, and promote healthy eating
            behaviors.
            <br></br>
            Here are key aspects of feeding therapy:<br></br>
            <b>1. Assessment:</b>
            Feeding therapy typically begins with a comprehensive assessment by
            a feeding therapist, which may include a review of the individual's
            medical history, observation of feeding behaviors, evaluation of
            oral motor skills, sensory processing assessment, and identification
            of any underlying medical or developmental factors impacting
            feeding.
            <br></br>
            <b> 2. Goal Setting: </b>
            Based on the assessment findings, the feeding therapist collaborates
            with the child's caregivers to establish specific feeding goals.
            These goals can vary depending on the individual's needs and may
            include increasing food acceptance, improving oral motor skills,
            enhancing self-feeding abilities, reducing aversions or anxiety
            related to food, and achieving appropriate nutritional intake.
          </Fade>
        </div>
      </div>
      <br></br>
      <div class="text-small ottext2 ">
        <Zoom>
          <b>3. Oral Motor Skill Development: </b>
          Feeding therapy often focuses on improving oral motor skills, which
          include the movements and coordination of the lips, tongue, jaw, and
          cheeks involved in eating and swallowing. Therapists may use exercises
          and techniques to strengthen and coordinate these muscles, improving
          chewing, sucking, swallowing, and overall oral motor control.
          <br></br> <b>4. Sensory Integration: </b>
          Many feeding difficulties are associated with sensory processing
          challenges. Feeding therapists address sensory issues related to food
          textures, flavors, temperatures, and oral sensitivities. They may use
          desensitization techniques, exposure therapy, and gradual food
          exploration to help individuals become more comfortable with a variety
          of food textures and tastes.
          <br></br>
        </Zoom>
        <Zoom>
          <b>5. Behavioral Approaches: </b>
          Feeding therapy often incorporates behavioral strategies to address
          challenging behaviors or negative associations with food. Therapists
          may use positive reinforcement, visual schedules, social modeling, and
          systematic desensitization to shape appropriate feeding behaviors and
          establish a positive mealtime environment.
          <br></br>
          <b> 6. Food Exploration and Acceptance: </b> Feeding therapy includes
          activities to expand the individual's food repertoire and increase
          acceptance of a wider variety of foods. Therapists may introduce new
          foods gradually, incorporate food play and exploration, and use
          strategies to make mealtimes more interactive and enjoyable.
        </Zoom>
        <br></br>
        <Zoom>
          <b>7. Family Involvement and Education: </b> Feeding therapy
          recognizes the crucial role of caregivers in supporting feeding
          progress. Therapists work closely with families, providing education
          on age-appropriate feeding practices, strategies for creating a
          positive mealtime environment, and techniques for encouraging healthy
          eating habits at home. They may also offer guidance on menu planning,
          food preparation, and addressing specific concerns related to the
          child's feeding difficulties.
          <br></br>
          <b>8. Collaboration with Parents and Caregivers: </b>
          In social skills therapy, collaboration with parents or caregivers is
          crucial. Therapists provide guidance, resources, and strategies to
          support the generalization of social skills at home and in the
          community. They may offer parent training, educate families about
          social skill development, and facilitate communication between therapy
          sessions and daily life.
          <br></br>
          Social skills therapy is typically provided by trained professionals,
          such as speech-language pathologists, psychologists, or occupational
          therapists. It can take place in various settings, including clinics,
          schools, or community-based programs.
          <br></br>The duration and frequency of social skills therapy depend on
          the individual's needs and goals. It is important to consult with a
          qualified therapist to determine the most appropriate intervention
          approach and to develop a personalized treatment plan that addresses
          the individual's specific social challenges.
        </Zoom>
      </div>
      <br></br>
      <br></br>
      <br></br> <br></br>
      <br></br>
      <br></br>
      <Footer />
    </>
  );
}

export default Feedtherapy;
