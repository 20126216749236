import logo from './logo.svg';
import Header from './components/header';
import Cover from './components/cover';
import './App.css';
import Routes from './Route.js';
import ExternalScripts from './components/ExternalScripts';

function App() {
  return (
    <div className="App App-header">
   
        {/* <Header/> */}
        {/* <Cover />  */}
        
        <ExternalScripts />
        <Routes />
      

    </div>
  );
}

export default App;
