import React from "react";
import Page from "./Page";
import Header from "./header";
import Footer from "./footer";

function OTvsPT() {
  return (
    <>
        <Page title="OT vs PT" />

      <Header />
      <div class="section-3 blog-post header">
        <div class="blog-post-image-overlay"></div>
        <div class="container-large-1016px blog-post hero-blog-post">
          <h1 class="display-heading article-title">
            OT vs PT: What's the difference? <br /> <br />
            How the ravages of history launched two profound professions
          </h1>

          <div class="blog-post-about-wrapper">
            <div class="blog-post-about-author-wrapper">
              <div class="blog-post-about-author-content">
                <div class="blog-post-about-author-name">
                  <strong>Posted: April 4, 2023</strong>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="section blog-post">
        <div class="container-large-1016px blog-post">
          <div class="rich-text w-richtext">
            <p>
              When a doctor recommends occupational therapy (OT), rather than
              physical therapy (PT), many people don't at first realize there
              are two kinds of therapies. It might also not be understood that
              there's a difference between them, or why a doctor prescribes one
              over the other. As therapists, we get this question a lot. For
              sure, there's a difference between the therapies and how they're
              used in healing and recovery. And the story is more interesting
              than you might imagine…{" "}
            </p>

            <h2>A short history of modern therapy</h2>
            <p>
              Movement and manual therapies can be traced back to ancient Asia,
              Greece and Rome. Those early practices included massage and
              hydrotherapy (water therapy).Fast-forward to modern-day therapy
              which began in 18th century Sweden with the practice of
              orthopedics — the medical focus on bones and muscles. A bit later,
              Hanrik Ling, developed the Swedish Gymnastic System (also known as
              the Swedish Movement Cure). His motivation? Having experienced the
              benefit of improved body movement through his practice of fencing.
              At its core, Ling's system emphasized physical conditioning for
              its ability to improve health and body function. It combined
              lesser intensive floor-style of gymnastics with manual therapy.
              Ling's approach brought wide acceptance of his methods. In 1813,
              the Swedish government appointed Ling to start the Royal Central
              Institute of Gymnastics (RCIG). Ling's system became wider spread
              as graduates of the RCIG adopted its four core components:
            </p>

            <ul role="list">
              <li>physical education</li>
              <li>fencing</li>
              <li>massage, physical therapy, physiotherapy</li>
              <li>dance performance</li>
            </ul>

            <p>
              One more fast-forward to the 1920s. The polio epidemic was raging
              in the United States, especially among children. During the
              epidemic, two schools of thought emerged. One used the practice of
              immobilizing the limbs of patients believing limb movement and
              stretching would impair muscle recovery and cause more deformity.
              A second practice re-introduced the Roman practice of
              hydrotherapy. Here therapists used exercise in heated pools to
              improve a patient's muscle recovery and movement. Polio paralysis
              spurred working with patients to improve balance and regain muscle
              strength. The benefit of warm water was helpful as well as
              buoyancy – water supports body weight and reduces stress on the
              joints. The practice – active polio therapy – helped grow a
              population of physical therapy (PT) specialists who became
              instrumental in treating polio paralysis. These early PTs
              developed methods for assessing and strengthening muscles –
              methods still used today. This piece of history gave the push to
              establishing the profession of physical therapy in the U.S.
              Turning now to the topic of occupational therapy (OT), its history
              doesn't stretch back as far as those of physical therapy. But its
              roots in America began to grown in the late 1800s. This happened
              primarily as a way to help individuals with mental illness by
              engaging them in meaningful tasks. Examples include gardening,
              painting and arts and crafts creation. The U.S. military also
              began recognizing the importance of mental health services for
              wounded and traumatized soldiers to help them resume daily living.
              This marked an entry for OT services in the treatment of
              individuals with mental and physical needs.
              <br />
              Three movements of thought were significant in the development of
              OT:
            </p>

            <ol role="list">
              <li>
                The consensus that mental health patients should be treated and
                not put in asylums or prisons.
              </li>
              <li>
                The reemergence of the value of manual occupation and vocational
                skills over mass production.
              </li>
              <li>
                The rise of thought that working with your hands to produce
                items of value can be beneficial to a person’s overall health.
              </li>
            </ol>
            <p>
              During this time, mental health asylums changed to reflect these
              new ideas. They were ideas of humane rehabilitation and included
              craft and recreational activities to help patients return to
              society through their contributions. These ideas were foundational
              for developing OT. About 1915, a social worker named Eleanor Clark
              Slagle started the first formal OT education program at John
              Hopkins University in Baltimore. Dubbed the "mother of
              occupational therapy" she trained more than 4,000 therapists and
              promoted OT within the medical community. While PT and OT
              therapies were in their infancy on the two sides of the Atlantic,
              it was America's entry into the Great War in 1917 where they came
              together. To summarize some of the above timeframes, it's worth a
              look at how it happened.
            </p>
            <h2>World War I and its transformation of therapy</h2>
            <p>
              World War I transformed medicine and contributed to the
              development of today's scope of medical care. The total number of
              military and civilian casualties in World War I was about 40
              million.. and about 23 million wounded military personnel. –
              Wikipedia
              <br />
              With the staggering number of wounded worldwide, orthopedics and
              therapists rapidly advanced to meet the need. The course of
              thought was that society had a moral responsibility to help these
              soldiers return to a normal and purposeful life. Thus, medical
              specialties developed to fill this need. The U.S. military hired a
              small group of women, calling them "reconstruction aides." In
              their roles they provided treatment by teaching occupation skills
              to the wounded. The initial 18 aides were trained in the latest
              European physiotherapy practices at the time. Aides were chosen
              from civilian women and women from the newly established
              profession of OT. Both therapy groups expanded rapidly to help the
              soldiers with recovery. Of the original 18 Aides, 16 went on to
              form the American Women's Physical Therapeutic Association. This
              later became the American Physical Therapy Association with
              McMillan as president.
              <br />– The U.S. World War One Centennial Commission.
            </p>
            <p>
              The work of these aides brought the military to begin seeing
              disability in terms of capability in function, and not as
              limitation. Their successes were many, helping wounded soldiers
              learn to walk again and freely move about in their environments.
              These early therapists gave training in the use of arm
              prosthetics, adapted home and work spaces and taught crafts and
              vocational skills for mental diversion and future employment.
            </p>
            <h2>The convergence of today's therapies and practitioners</h2>
            <p>
              After WWI, occupational and physical therapy continued to advance.
              And with their evolutions came recognition for the benefits they
              each provided. But sometimes there was division in which to
              prescribe: occupational therapy vs physical therapy. What became
              clear over time, with advances in the professions, is the benefit
              to individuals when providing therapies in tandem. Today's
              occupational and physical therapists work together in the shared
              goal of improving an individual’s function through movement. As
              individual medical treatments, the therapies, when used together,
              can have profound results. Because of this they are recognized as
              separate but symbiotic professions. Similarities include:
            </p>
            <h3>Working in a variety of settings</h3>
            <ul role="list">
              <li>Schools</li>
              <li>Outpatient rehabilitation centers</li>
              <li>Home health agencies</li>
              <li>Hospitals</li>
              <li>Nursing homes</li>
            </ul>
            <h3>Training in key disciplines</h3>
            <ul role="list">
              <li>Science</li>
              <li>Anatomy</li>
              <li>Kinesiology</li>
            </ul>
            <h3>Using similar therapy techniques</h3>

            <ul role="list">
              <li>Soft tissue mobilization</li>
              <li>Functional activities</li>
              <li>Strengthening</li>
              <li>Stretching</li>
              <li>Pain relief</li>
            </ul>
            <h2>Opposites attract, even in medicine</h2>
            <p>
              Even with similarities between the two professions, there are also
              key differences. Physical therapy focuses on improving movement,
              flexibility and mobility. This includes improving physical motion
              required for a task. Physical therapy has a unique approach to
              mobility – movement and muscle balance. It uses prescribed
              treatment techniques to maximize function, capacity and
              performance. PTs work with patients before and after surgery to
              build strength and kick-start healing. Therapy uses movement to
              reduce pain, recover from an injury and promote balance to reduce
              the risk of falls. The occupational therapy profession has a
              different focus. That focus is on functional ability – the ability
              of an individual to do activities, work and tasks that are
              normally performed in everyday living and occupation.
            </p>

            <p>
              That focus gives occupational therapy its name. Occupation is
              defined as an activity that is meaningful and purposeful to the
              individual. It can include basic activities such as dressing,
              bathing or fixing a meal. It can also include specific activities
              which are unique to the individual. For example, you may be a high
              school teacher, home gardener, pianist or an electrician. Each
              occupation requires a unique set of activities. If those
              activities are compromised by illness or injury, OTs can help.
              Your therapist will assess your current function and how to
              improve your ability to perform a task or modify it to help you
              complete it. Now that you know more about the differences between
              OT and PT, should you need therapy you'll be able to spot the
              differences in what your therapist recommends. Of course, it all
              depends on your condition, your needs, and personal goals. But
              thanks to a long history of the disciplines, and more than a
              century of experience since WWI, PTs and OTs are specialists in
              your care.
            </p>
            <p>
              Reference:{" "}
              <a href="https://www.kesslerrehabilitationcenter.com/why-choose-us/blog/ot-vs-pt-whats-the-difference/"
              target="_blank">
                {" "}
                Kessler Rehabilitation Center{" "}
              </a>
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default OTvsPT;
