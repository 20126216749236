import React from "react";
import "./tassets/totalcare.css";
import clogo from "../assets/clogo.png";
import occupational from "./tassets/occupational.jpg";
import sa from "./tassets/sa.jpg";
import sit from "./tassets/sit.jpg";
import gms from "./tassets/grossmotorskill.jpeg";
import fms from "./tassets/finemotorskill.jpg";
import Page from "./Page";
import pickyeaters from "./tassets/pickyeaters.jpg";
import assistive from "./tassets/assistive.jpg";
import aq from "./tassets/aq.jpg";
import play from "./tassets/play.jpg";
import adl from "./tassets/adl.jpg";
import Header from "./header";
import Footer from "./footer";
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';



function Ot() {
  return (
      <>
        <Page title="Occupational Therapy" />

      <Header />
      <div className="servicediv">
      <Fade left delay={700}>
      <h4 className="oth">Occupational Therapy</h4>
      <br></br> <br></br>
      </Fade>
      <div class="otit">
        <div className="otpicdiv">
        <Zoom delay={500}>
          <img className="otpic" src={occupational} alt="occupational"></img>
        </Zoom>
        </div>
        <div class="text-small ottext ">
        <Fade bottom delay={300}>
        Occupational therapy is a vital healthcare discipline that empowers individuals to overcome physical, emotional, or cognitive challenges hindering their daily life activities. Occupational therapists work with diverse populations, including those with disabilities, injuries, or developmental disorders. By assessing, designing, and implementing customized interventions, they aim to enhance independence, improve quality of life, and promote overall well-being. Occupational therapy fosters self-sufficiency and participation in meaningful activities, ultimately facilitating individuals in achieving their maximum potential.
          <br></br>
          </Fade>

        
        </div>
      </div>
     
      <div class="section less-padding">
      <div class="container-default">
        <h2 class="title about-story">Our Services in Occupational Therapy</h2>
        <div class="services-cards">
          <div class="services-card"><img
              src={sa}
              
              sizes="(max-width: 479px) 100vw, (max-width: 767px) 75vw, (max-width: 991px) 46vw, (max-width: 1439px) 30vw, 380px"
              
              alt="standard assessment" class="services-card-img" />
            <div class="services-card-content">
              <h2 class="services-card-title">Standard Assessment</h2>
              <p class="services-card-subtitle">A systematic evaluation using established criteria or measures to gauge performance, skills, or compliance with established benchmarks or norms.<br /></p>
            </div>
          </div>
          <div class="services-card"><img
              src={sit}
              
              sizes="(max-width: 479px) 100vw, (max-width: 767px) 75vw, (max-width: 991px) 46vw, (max-width: 1439px) 30vw, 380px"
             
              alt="Sensory Integration Therapy" class="services-card-img" />
            <div class="services-card-content">
              <h2 class="services-card-title">Sensory Integration Therapy</h2>
              <p class="services-card-subtitle">A specialized approach helping individuals process sensory information, fostering better responses to sensory stimuli for improved function and behavior.<br /></p>
            </div>
          </div>
          <div class="services-card"><img
              src={gms}
              
              sizes="(max-width: 479px) 100vw, (max-width: 767px) 75vw, (max-width: 991px) 46vw, (max-width: 1439px) 30vw, 380px"
              
              alt="gross motor skills" class="services-card-img" />
            <div class="services-card-content">
              <h2 class="services-card-title">Gross Motor Skills</h2>
              <p class="services-card-subtitle">Gross motor skills, in occupational therapy, refer to the development and improvement of large muscle movements and coordination essential for activities like walking, running, and jumping. Therapists work to enhance these abilities in individuals of all ages.<br /></p>
            </div>
          </div>

          <div class="services-card"><img
              src={fms}
              
              sizes="(max-width: 479px) 100vw, (max-width: 767px) 75vw, (max-width: 991px) 46vw, (max-width: 1439px) 30vw, 380px"
              
              alt="fine motor skills" class="services-card-img" />
            <div class="services-card-content">
              <h2 class="services-card-title">Fine Motor Skills</h2>
              <p class="services-card-subtitle">Fine motor skills, within occupational therapy, pertain to the refinement of precise, small muscle movements, crucial for tasks like writing, buttoning, and using utensils.<br /></p>
            </div>
          </div>
          <div class="services-card"><img
              src={play}
              
              sizes="(max-width: 479px) 100vw, (max-width: 767px) 75vw, (max-width: 991px) 46vw, (max-width: 1439px) 30vw, 380px"
              
              alt="play therapy" class="services-card-img" />
            <div class="services-card-content">
              <h2 class="services-card-title">Play therapy</h2>
              <p class="services-card-subtitle">A therapeutic approach using play and toys to help child express emotions, address trauma, and develop coping skills.<br /></p>
            </div>
          </div>
          <div class="services-card"><img
              src={adl}
              
              sizes="(max-width: 479px) 100vw, (max-width: 767px) 75vw, (max-width: 991px) 46vw, (max-width: 1439px) 30vw, 380px"
              
              alt="ADL Training" class="services-card-img" />
            <div class="services-card-content">
              <h2 class="services-card-title">ADL Training</h2>
              <p class="services-card-subtitle">Rehabilitation process teaching individuals essential Activities of Daily Living (ADLs) for enhanced self-sufficiency and independence in daily routines.<br /></p>
            </div>
          </div>
          <div class="services-card"><img
              src={pickyeaters}
              
              sizes="(max-width: 479px) 100vw, (max-width: 767px) 75vw, (max-width: 991px) 46vw, (max-width: 1439px) 30vw, 380px"
             
              alt="Picky Eaters" class="services-card-img" />
            <div class="services-card-content">
              <h2 class="services-card-title">Picky Eaters</h2>
              <p class="services-card-subtitle">Picky eaters are individuals, often children, who exhibit selective eating habits, showing reluctance to try or eat a wide variety of foods.<br /></p>
            </div>
          </div>
          <div class="services-card"><img
              src={assistive}
              
              sizes="(max-width: 479px) 100vw, (max-width: 767px) 75vw, (max-width: 991px) 46vw, (max-width: 1439px) 30vw, 380px"
              
              alt="Assistive Devices" class="services-card-img" />
            <div class="services-card-content">
              <h2 class="services-card-title">Assistive Devices</h2>
              <p class="services-card-subtitle">Tools or technologies that aid individuals with disabilities in performing tasks, enhancing independence, and improving quality of life.<br /></p>
            </div>
          </div>
          <div class="services-card"><img
              src={aq}
              
              sizes="(max-width: 479px) 100vw, (max-width: 767px) 75vw, (max-width: 991px) 46vw, (max-width: 1439px) 30vw, 380px"
             
              alt="Adaptive Equipment" class="services-card-img" />
            <div class="services-card-content">
              <h2 class="services-card-title">Adaptive Equipment</h2>
              <p class="services-card-subtitle">Customized tools or modifications to existing devices that enable individuals with disabilities to engage in activities and routines effectively.<br /></p>
            </div>
          </div>
        </div>
      </div>
    </div>
      <br></br>
      <br></br>
      <br></br>
    
    <br></br>
    <br></br>
    <br></br> <br></br>
    </div>
      <Footer />
    </>
  );
}

export default Ot;
