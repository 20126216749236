// Page.js
import { useEffect } from 'react';

const Page = ({ title }) => {
  useEffect(() => {
    document.title = title;
  }, [title]);

  return null; // or render your page content here
};

export default Page;
