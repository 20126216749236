import React from 'react'
import "./tassets/totalcare.css";
import clogo from "../assets/clogo.png";
import address from "./tassets/address.png";
import mail from "./tassets/mail.png";
import phone from "./tassets/phone.png";

function Footer ()  {
  return (
    <>
     <div class="footer wf-section">
        <div class="main-container">
          <div class="footer-top-row">
            <div class="footer-logo-and-menus">
              <a
                href="/"
                aria-current="page"
                class="footer-logo w-inline-block w--current"
              >
                <img
                  src={clogo}
                  style={{ width:"145px"}}
                 
                  sizes="100px"
                  srcset=""
                  alt=""
                  class="logo-footer"
                />
              </a>
              {/* <div class="w-layout-grid menu-grid-vertical">
                <a
                  href="/"
                  aria-current="page"
                  class="hover-link text-white w--current"
                >
                  Home
                </a>
                <a href="/about" class="hover-link text-white">
                  About
                </a>
                <a href="/services" class="hover-link text-white">
                  Services
                </a>
                <a href="/careers" class="hover-link text-white">
                  Careers
                </a>
                <a href="/insurances" class="hover-link text-white">
                  Insurances
                </a>
                <a href="/team" class="hover-link text-white">
                  Team
                </a>
              </div> */}
              {/* <div class="w-layout-grid menu-grid-vertical">
                <a href="/contact-us" class="hover-link text-white">
                  Contact Us
                </a>
                <a href="/locations" class="hover-link text-white">
                  Locations
                </a>
                <a href="/statistics" class="hover-link text-white">
                  Research
                </a>
                <a href="/autism" class="hover-link text-white">
                  Learning Center
                </a>
                <a href="/refer-a-patient" class="hover-link text-white">
                  Refer To us
                </a>
              </div> */}
              <div class="w-layout-grid menu-grid-vertical contact-links">
                <div
                  id="w-node-f1264d15-80c8-4991-c7b7-33e6975f21b9-6dcb7750"
                  class="footer-link-with-icon"
                >
                  <img
                    src={phone}
                    
                    alt=""
                    class="footer-icon"
                  />
                  <a href="tel: +923011605000" class="hover-link text-white">
                    +923011605000
                  </a>
                </div>
                <div
                  id="w-node-f1264d15-80c8-4991-c7b7-33e6975f21b9-6dcb7750"
                  class="footer-link-with-icon"
                >
                  <img src={phone}   alt="" class="footer-icon" />
                  <a href="tel: +9665330679337" class="hover-link text-white">
                    +9665330679337  
                  </a>
                  
                  <span class="hover-link text-white" style={{width:"150px"}}> (For WhatsApp)</span> 
                </div>
                
                {/* <div
                  id="w-node-_9064a1db-1b1b-0fc6-d66c-758b86ea184b-6dcb7750"
                  class="footer-link-with-icon"
                >
                  <img
                    src="https://global-uploads.webflow.com/63e413c214acc42465a98a2a/63e413c214acc47e46a98b58_fax%201.svg"
                    
                    alt=""
                    class="footer-icon"
                  />
                  <a href="tel:(404)400-5003" class="hover-link text-white">
                    (404) 400-5003
                  </a>
                </div> */}
                <div
                  id="w-node-_3e76cf14-9b6d-4119-3663-4dc656f9b0df-6dcb7750"
                  class="footer-link-with-icon"
                >
                  <img
                    src={mail}
                     
                    alt=""
                    class="footer-icon"
                  />
                  <a
                    href="mailto: info@cpat.com.pk"
                    class="hover-link text-white"
                  >
                    info@cpat.com.pk
                  </a>
                </div>

                <div
                  id="w-node-f1264d15-80c8-4991-c7b7-33e6975f21b9-6dcb7750"
                  class="footer-link-with-icon"
                >
                  <img
                    src={address}
                    // style={{color:"white"}}
                    
                    alt=""
                    class="footer-icon"
                  />
                  <a  href="https://g.co/kgs/4LJ1YN" class="hover-link text-white">
                    Address: Imtiaz Shaheed Road, near Prime Care Hospital, Block X, Madina Town, Faisalabad, Punjab, Pakistan.
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="footer-bottom-row footer-3-bottom-row">
            <div class="footer-bottom-links">
              <a class="row-link">
                Privacy Policy
              </a>
              <div>
                C-PAT © <span id="current-year-footer">2024.</span> All
                rights reserved.
                <strong>
                  <br />
                </strong>
              </div>
            </div>
            <div class="social-links">
              <a
                href="https://www.facebook.com/info.cpat"
                target="_blank"
                class="social-link hover-link w-inline-block"
              >
                <img
                  src="https://global-uploads.webflow.com/63e413c214acc42465a98a2a/63e413c214acc47c5ea98a72_facebook.svg"
                  alt=""
                  class="social-link-image"
                />
              </a>
              <a
                href=""
                target="_blank"
                class="social-link hover-link w-inline-block"
              >
                <img
                  src="https://global-uploads.webflow.com/63e413c214acc42465a98a2a/63e413c214acc4cd4ba98a71_linkedin.svg"
                  alt=""
                  class="social-link-image"
                />
              </a>
              <a
                href=""
                // target="_blank"
                class="social-link hover-link w-inline-block"
              >
                <img
                  src="https://global-uploads.webflow.com/63e413c214acc42465a98a2a/63e413c214acc475dba98adf_instagram.png"
                  alt=""
                  class="social-link-image"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
        
    </>
  )
}

export default Footer