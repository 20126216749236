import { BrowserRouter, Route, Routes } from "react-router-dom";


import Ot from './components/ot';
import Cover from './components/cover';
import Header from './components/header';
import Footer from "./components/footer";
import Eip from "./components/eip";
import It from "./components/it";
import Pt from "./components/pt";
import Speech from "./components/speech";
import Social from "./components/social";
import ScrollToTop from "./components/scrolltop";
import Feedtherapy from "./components/feedtherapy";
import Pickyeater from "./components/pickyeater";
import Psychomotor from "./components/psychomotor";
import Handwriting from "./components/handwriting";
import Aba from "./components/aba";
import Montessori from "./components/montessori";
import Playandart from "./components/play";
import Sensory from "./components/sensory";
import Homebase from "./components/homebase";
import Nutrition from "./components/nutrition";
import Parentstrain from "./components/parentstrain";
import Individualize from "./components/individualize";
import WhatIsAutism from "./components/WhatIsAutism";
import WhatIsAbaTherapy from "./components/WhatIsAbaTherapy";
import WhyAbaEffective from "./components/WhyAbaEffective";
import BeniftsOfAbaTherapy from "./components/BeniftsOfAbaTherapy";
import OTvsPT from "./components/OTvsPT";
import PhysicalTherapy from "./components/PhysicalTherapy";
import PtforNeckPain from "./components/PtforNeckPain";
import ReasonstostartPt from "./components/ReasonstostartPt";
import SpeechTherapy from "./components/SpeechTherapy";
import SpeechScreenings from "./components/SpeechScreenings";
import TipsforSpeechTherapySession from "./components/TipsforSpeechTherapySession";
import AboutUs from "./components/AboutUs";





function RouteConfig() {
 
  return <>
	<div>
      <BrowserRouter>

      {/* <Cover /> */}
        
      <ScrollToTop>
        <Routes>
        <Route path="/" element={<Cover />} />
        
        <Route path="/ot" element={<Ot />} />
        <Route path="/header" element={<Header />} />
        <Route path="/footer" element={<Footer />} />
        <Route path="/eip" element={<Eip />} />
        <Route path="/it" element={<It />} />
        <Route path="/pt" element={<Pt />} />
        <Route path="/speech" element={<Speech />} />
        <Route path="/social" element={<Social />} />
        <Route path="/feedtherapy" element={<Feedtherapy />} />
        <Route path="/pickyeater" element={<Pickyeater />} />
        <Route path="/psychomotor" element={<Psychomotor />} />
        <Route path="/handwriting" element={<Handwriting />} />
        <Route path="/aba" element={<Aba />} />
        <Route path="/montessori" element={<Montessori />} />
        <Route path="/play" element={<Playandart />} />
        <Route path="/sensory" element={<Sensory />} />
        <Route path="/homebase" element={<Homebase />} />
        <Route path="/nutrition" element={<Nutrition />} />
        <Route path="/parentstrain" element={<Parentstrain />} />
        <Route path="/individualize" element={<Individualize />} />
        <Route path="/WhatIsAutism" element={<WhatIsAutism />} />
        <Route path="/WhatIsAbaTherapy" element={<WhatIsAbaTherapy />} />
        <Route path="/WhyAbaEffective" element={<WhyAbaEffective />} />
        <Route path="/BenifitsOfAbaTherapy" element={<BeniftsOfAbaTherapy />} />
        <Route path="/OTvsPT" element={<OTvsPT />} />
        <Route path="/PhysicalTherapy" element={<PhysicalTherapy />} />
        <Route path="/PtforNeckPain" element={<PtforNeckPain />} />
        <Route path="/ReasonstostartPt" element={<ReasonstostartPt />} />
        <Route path="/SpeechTherapy" element={<SpeechTherapy />} />
        <Route path="/SpeechScreenings" element={<SpeechScreenings />} />
        <Route path="/TipsforSpeechTherapySession" element={<TipsforSpeechTherapySession />} />
        <Route path="/AboutUs" element={<AboutUs />} />


           </Routes>
        </ScrollToTop>
            
    </BrowserRouter>
        
	</div>
  </>
  }
  
  export default RouteConfig;
