import React from "react";
import "./tassets/totalcare.css";
import clogo from "../assets/clogo.png";
import eip from "./tassets/eip.jpg";
import Header from "./header";
import Footer from "./footer";
import Fade from "react-reveal/Fade";
import Zoom from "react-reveal/Zoom";

function Eip() {
  return (
    <>
      <Header />
      <Fade left delay={700}>
        <h4 className="oth">Early Intervention Programme</h4>
        <br></br> <br></br>
      </Fade>
      <div class="otit">
        <div className="otpicdiv">
          <Zoom delay={500}>
            <img className="otpic" src={eip}></img>
          </Zoom>
        </div>
        <div class="text-small ottext ">
          <Fade bottom delay={300}>
            An early intervention plan refers to a structured approach that aims
            to identify and address developmental delays or disabilities in
            young children. Early intervention services are designed to support
            children from birth to age three and their families by providing
            specialized interventions and supports to promote optimal
            development.
            <br></br>
            Here are key aspects of an early intervention plan <br></br>
            <b>1. Early Identification and Assessment: </b>
            Early intervention begins with the identification of potential
            developmental delays or concerns. Screening tools and assessments
            are used to evaluate a child's development across various domains,
            including cognitive, communication, motor, social-emotional, and
            adaptive skills. These assessments help determine if a child
            qualifies for early intervention services and inform the development
            of an individualized intervention plan.
            <br></br>
            <b> 2. Individualized Family Service Plan (IFSP): </b>
            Individualized Family Service Plan (IFSP) is developed. The IFSP is
            a written document that outlines the child's strengths, needs, and
            family priorities. It includes goals and objectives for the child's
            development and the specific early intervention services and
            supports that will be provided. The IFSP is developed
            collaboratively with the child's family and a team of professionals,
            which may include early intervention specialists, therapists,
            educators, and service coordinators.
          </Fade>
        </div>
      </div>
      <br></br>
      <div class="text-small ottext2 ">
        <Zoom>
          <b> 3. Early Intervention Services: </b>
          Early intervention services are tailored to meet the unique needs of
          the child and their family as identified in the IFSP. These services
          can include a range of interventions, such as:<br></br>- Developmental
          therapy: Therapists work directly with the child to address
          developmental delays or challenges in areas such as communication,
          motor skills, cognition, or social-emotional development.<br></br>-
          Speech and language therapy: Speech-language pathologists provide
          interventions to support the child's communication skills, including
          speech production, language comprehension, and social communication.
          <br></br>- Occupational therapy: Occupational therapists focus on
          improving a child's fine motor skills, sensory processing, self-help
          skills, and overall functional abilities.<br></br>- Physical therapy:
          Physical therapists address the child's gross motor skills, mobility,
          coordination, and physical abilities.<br></br>- Special education
          services: Educators provide instructional support to address
          cognitive, academic, and behavioral needs, ensuring the child's
          participation and progress in educational activities.<br></br>- Family
          support and counseling: Early intervention programs often offer
          counseling and guidance to support the child's family. This can
          include providing information, resources, and emotional support to
          parents or caregivers and helping them navigate the challenges of
          raising a child with developmental delays.<br></br>
          <br></br>
          <b>4. Ongoing Monitoring and Evaluation: </b>
          The early intervention plan is continuously monitored and evaluated to
          track the child's progress towards their goals. Regular assessments,
          observations, and progress reviews are conducted to determine the
          effectiveness of the interventions and make necessary adjustments to
          the plan. Family input and collaboration are essential throughout this
          process.
          <br></br>
        </Zoom>
        <Zoom>
          <b> 5. Transition Planning: </b>
          As a child approaches the age of three, transition planning becomes a
          crucial component of the early intervention plan. Transition meetings
          are held to discuss and plan for the child's transition from early
          intervention services to appropriate preschool or educational
          programs. This involves coordinating services, facilitating smooth
          transitions, and ensuring continuity of support.<br></br>
          Early intervention is based on the understanding that early
          identification and intervention can have a significant impact on a
          child's development and long-term outcomes. By providing specialized
          services and supports during this critical period, early intervention
          aims to enhance a child's developmental trajectory, improve their
          functional abilities, and support their overall well-being.<br></br>
          It's important to consult with early intervention professionals or
          agencies in your local area to access specific services and programs
          available to support your child's needs.
          <br></br>
        </Zoom>
        <br></br>
      </div>
      <br></br>
      <br></br>
      <br></br> <br></br>
      <br></br>
      <br></br>
      <Footer />
    </>
  );
}

export default Eip;
