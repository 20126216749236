import React from 'react'
import "./tassets/totalcare.css";
import Header from "./header";
import Footer from "./footer";
import Page from './Page';
function WhatIsAbaTherapy  ()  {
  return (
    <>
        <Page title="What Is Aba Therapy" />

    <Header/>
    <div
    //   style="background-image:url(&quot;https://assets-global.website-files.com/63e413c214acc4a298a98a44/649536cb7202c93978a82011_-aba%20therapy.jpg&quot;)"
      class="section-3 blog-post header">
      <div class="blog-post-image-overlay"></div>
      <div class="container-large-1016px blog-post hero-blog-post">
        <h1 class="display-heading article-title">What Is Applied Behavior Analysis?</h1>
        <p class="text-large article-subtitle">Applied Behavior Analysis (ABA) therapy was developed in the 1960s as a
          way to increase wanted behaviors and decrease unwanted ones.</p>
        <div class="blog-post-about-wrapper">
          <div class="blog-post-about-author-wrapper">
            <div class="image-wrapper blog-post-about-author"><img
                src="https://global-uploads.webflow.com/63e413c214acc42465a98a2a/63e413c214acc465fba98b83_ruben.jpeg"
                alt="reuben kesherim" class="image-2 blog-post-about-author" /></div>
            <div class="blog-post-about-author-content"><a href="https://www.totalcareaba.com/team/ruben-kesherim"
                target="_blank" class="blog-post-about-author-link w-inline-block">
                <div class="blog-post-about-author-name"><strong>Ruben Kesherim</strong></div>
              </a>
              <div class="blog-post-about-date">July 28, 2023</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section blog-post">
      <div class="container-large-1016px blog-post">
        <div class="rich-text w-richtext">
          <h2>What Is ABA Therapy?</h2>
          <p>Applied Behavior Analysis (ABA) therapy was developed in the 1960s as a way to increase wanted behaviors
            and decrease unwanted ones. </p>
          <p>It is most widely known for its use in behavior modification in children with autism, but it has also been
            used for other mental health disorders where behavior modification would be beneficial. </p>
          <p>Total Care ABA specializes in <a href="https://www.crossrivertherapy.com/what-is-aba-therapy"
              target="_blank">ABA therapy</a> to help improve the lives of children with autism, those with other mental
            health conditions, and those who care for them.</p>
          {/* <figure class="w-richtext-figure-type-image w-richtext-align-fullwidth" style="max-width:1485pxpx"
            class="w-richtext-align-fullwidth w-richtext-figure-type-image">
            <div><img
                src="https://global-uploads.webflow.com/63e413c214acc4a298a98a44/63ea6f85f13ecbfb9ba51898_ABA%201%20(1).jpg"
                loading="lazy" alt="" /></div>
          </figure> */}
          <h2><strong>What is Applied Behavior Analysis?</strong></h2>
          <p><a href="https://www.bridgecareaba.com/blog/what-is-aba-therapy" target="_blank">ABA therapy</a> was
            developed in the 1960s by Dr. Ivar Lovass and was based on the concept that autism comprised a set of
            behavioral symptoms that can be extinguished or modified with training. </p>
          <p>Back then, the person was considered &quot;treated&quot; when the behaviors no longer existed. Now, we know
            that autism and its related conditions are more complex than a set of behaviors.</p>
          <blockquote>ABA has evolved to include our understanding of how behaviors develop, the role of the social and
            learning environment, and how learning happens. </blockquote>
          <p>Many forms of ABA therapy exist today, and each of them is based on decreasing unwanted behaviors and
            increasing desired ones. </p>
          <p>The therapy is intensive and typically takes place in a one-on-one environment with a Board Certified
            Assistant Behavior Analyst (BCaBA).</p>
          <p>Now, the focus of therapy is not to &quot;treat&quot; autism but to help the person with autism develop
            strategies to live full, independent lives and to give caregivers strategies for managing unwanted
            behaviors. This can take many forms and means something different for every person with autism.</p>
          <p><a href="https://www.ambitionsaba.com/what-is-aba" target="_blank">ABA therapy</a> used to focus only on
            small children, with early childhood intervention as the preferred scenario. </p>
          <p>This means training medical personnel and others to spot the early signs of autism, so the child can enter
            treatment as early as possible. Now, many adults with autism are turning to ABA to help them learn better
            coping strategies.</p>
          <p>Today, most ABA therapy programs are divided into five types.</p>
          <h3><strong>Discrete Trial Teaching</strong></h3>
          <p>This form of ABA therapy was the type used by Dr. Lovaas. It involves breaking complex tasks down into
            small steps. Mastery of each small step is rewarded using a cue-and-response structure to the instruction.
          </p>
          <h3><strong>Naturalistic Teaching</strong></h3>
          <p>Naturalistic teaching allows the student to set his or her own pace throughout the day. The lessons and
            therapy sessions are integrated into their daily routine. This allows the child to associate the desired
            behaviors with a familiar environment.</p>
          <h3><strong>Pivotal Response Training</strong></h3>
          <p>Pivotal response training is student-directed teaching. The child is the one to dictate the learning
            environment. This method improves motivation to learn and allows the therapist to work on more than one cue
            at a time.</p>
          <h3><strong>Token Economy</strong></h3>
          <p>Token economy ABA therapy uses a system where the child earns tokens for wanted behaviors and loses them
            for unwanted ones. The child can then exchange tokens for rewards. This is similar to how currency works in
            the real world.</p>
          <h3><strong>Contingent Observation</strong></h3>
          <p>This learning strategy involves placing the child in a group of peers. When the child sees that their
            behavior is not acceptable, they are encouraged to watch the acceptable behavior of their peers. This one
            also helps the person with autism build social relations.</p>
          <h2><strong>How does ABA therapy work?</strong></h2>
          <p>Regardless of the type of ABA therapy, all of them work on the principle that wanted behaviors are rewarded
            and unwanted ones are not rewarded. Punishment is no longer a part of ABA therapy. Current methods involve
            identifying the person&#x27;s strengths and capitalizing on them and helping them to overcome challenges. It
            involves helping people with autism acquire social and developmental skills that did not come to them
            naturally.</p>
          {/* <figure class="w-richtext-figure-type-image w-richtext-align-fullwidth" style="max-width:1485pxpx"
            class="w-richtext-align-fullwidth w-richtext-figure-type-image">
            <div><img
                src="https://global-uploads.webflow.com/63e413c214acc4a298a98a44/63ea6fea2bd4de69ef9ce791_ABA%202%20(1).jpg"
                loading="lazy" alt="" /></div>
          </figure> */}
          <h3><strong>Positive Reinforcement</strong></h3>
          <p>Positive reinforcement is the heart of ABA therapy. This involves giving the person rewards for wanted
            behaviors with the idea that this will encourage them to repeat the behavior. Examples of wanted behaviors
            might be:</p>
          <ul role="list">
            <li>Language and communication skills</li>
            <li>Attention and focus</li>
            <li>Social skills</li>
            <li>Self care</li>
            <li>Academics</li>
          </ul>
          <p>The program is flexible and does not take a one-size-fits-all approach to the reward system. The goal of
            the initial evaluation is to determine an appropriate motivator for the person. Some rewards might be food
            or time with a favorite toy.</p>
          <p>In each session, a target goal behavior is identified and the child gets a reward every time they perform
            the task. Over time, this process leads to a meaningful change in behavior. More complex tasks are broken
            into small steps that eventually combine until the desired behavior can be performed.</p>
          <h3><strong>Antecedent, Behavior, Consequence</strong></h3>
          <p>Antecedent, behavior, and consequence are the other side of the equation in ABA therapy. The word
            &quot;punishment&quot; is no longer used. The first step to curbing a behavior is to identify what events or
            actions come before it.</p>
          <p>Once the antecedent behavior is identified, an attempt is made to cut the behavior off before it starts.
            Most of the time this is with a verbal cue or reminder. If the behavior occurs, then the person receives a
            consequence.</p>
          <p>The consequence can be not receiving a reward or having a favorite toy taken away. This is often the most
            misunderstood part of ABA therapy, as the consequence is not a true &quot;punishment.&quot; It focuses more
            on identifying triggers to unwanted behavior and teaching the person coping strategies when they occur or
            removing them from the environment.</p>
          <p>Some examples of behaviors to be reduced or eliminated include:</p>
          <ul role="list">
            <li>Stimming</li>
            <li>Vocal outbursts</li>
            <li>Self harm</li>
            <li>Throwing toys</li>
            <li>Interrupting conversations</li>
          </ul>
          <p>The focus of this component of ABA therapy is on positive intervention. Each child who enters an ABA
            program has a unique set of behavioral challenges to overcome and goals for wanted behaviors. The program
            begins with an evaluation of the person and setting specific goals that will drive the individual therapy
            plan.</p>
          <h2><strong>What Does an ABA Program Involve?</strong></h2>
          <p>The key to a successful ABA program is the initial assessment. This assessment focuses on identifying the
            person&#x27;s strengths and challenges. This evaluation will result in goals that address each specific goal
            or challenge in a way that can be measured and tracked.</p>
          {/* <figure class="w-richtext-figure-type-image w-richtext-align-fullwidth" style="max-width:1485pxpx"
            class="w-richtext-align-fullwidth w-richtext-figure-type-image">
            <div><img
                src="https://global-uploads.webflow.com/63e413c214acc4a298a98a44/63ea700cb692ea613fe57013_ABA%203%20(1).jpg"
                loading="lazy" alt="" /></div>
          </figure> */}
          <p>During the initial assessment, you will fill out a questionnaire asking about your child&#x27;s behavior
            and history. After this, the specialist will have an observation period to see your child&#x27;s
            interactions with others. They will observe how your child interacts with you, any siblings, and the
            assessor.</p>
          <p>The observation period also involves having your child perform various tasks, such as shape sorting, or
            other developmentally appropriate activities. This assessment takes anywhere from 2-4 hours. It can take
            place at home or a facility.</p>
          <p>Once the evaluator has completed these portions, they will discuss what they see as your child&#x27;s
            strengths and weaknesses. You can also add input and your own experiences with the child. You can discuss
            your expectations and help set prioritized goals for the program.</p>
          <p>The evaluator will take the data from the assessment and your agreed-upon goals to use in developing a
            treatment plan. This can take an additional one to two weeks, on average. The evaluator will then schedule a
            meeting with you to discuss the plan and make arrangements to get started.</p>
          <h2><strong>Planning and Ongoing Assessment</strong></h2>
          <p>Depending on the needs of the child, therapy can take place in the home, at school, using inpatient
            programs, or in the community. Parents receive one-on-one instruction on how to support the therapy process.
            ABA treatments are designed with the needs of each individual in mind and are modified as needed for the
            child.</p>
          {/* <figure class="w-richtext-figure-type-image w-richtext-align-fullwidth" style="max-width:1485pxpx"
            class="w-richtext-align-fullwidth w-richtext-figure-type-image">
            <div><img
                src="https://global-uploads.webflow.com/63e413c214acc4a298a98a44/63ea704f495f5ae5ef15c979_ABA%204%20(1).jpg"
                loading="lazy" alt="" /></div>
          </figure> */}
          <p>Ongoing assessment and evaluation are important parts of the treatment process. These are built into the
            initial therapy plan, and they can be modified as needed as therapy progresses. This ongoing evaluation and
            assessment allow for factors, such as areas where your child progresses more rapidly or slower than
            expected.</p>
          <p>ABA therapy plans are designed to be able to be adjusted to the changing needs and progress of the child.
            Frequent evaluation allows the program to be responsive to the child and allows them to progress at their
            own pace. It also helps to identify areas where more intensive therapy is needed and where the goals have
            been met.</p>
          <h2><strong>ABA Techniques and Philosophy</strong></h2>
          <p>The key advantage of ABA therapy is that it can be modified, adjusted, and made to meet the specific needs
            of each child. From the initial evaluation to the need to modify the plan, ABA depends on frequent
            evaluation and responsiveness to your child. The continual evaluation process allows the therapist to
            suggest additional techniques that might be helpful to the child.</p>
          {/* <figure class="w-richtext-figure-type-image w-richtext-align-fullwidth" style="max-width:1485pxpx"
            class="w-richtext-align-fullwidth w-richtext-figure-type-image">
            <div><img
                src="https://global-uploads.webflow.com/63e413c214acc4a298a98a44/63ea708598d748fa7fe45129_ABA%205%20(1).jpg"
                loading="lazy"
                alt="The key advantage of ABA therapy is that it can be modified, adjusted, and made to meet the specific needs of each child." />
            </div>
          </figure> */}
          <p>ABA focuses on the positive aspects of reinforcement and rewards with an emphasis on the comfort and needs
            of the child and their caregivers. Some of the techniques and strategies therapists have at their disposal
            include:</p>
          <ul role="list">
            <li>Positive reinforcement</li>
            <li>Video modeling</li>
            <li>Prompt and fading</li>
            <li>Natural environment teaching</li>
            <li>Behavior chain</li>
            <li>Generalization</li>
            <li>Behavior contracts</li>
          </ul>
          <p>Many other techniques are available than those on this list. Your therapist will choose the ones that are
            the best match for your child&#x27;s skill level, developmental age, and plan goals. The therapist will
            design a plan that has the right mix of techniques to help your child achieve their goals.</p>
          <h2><strong>What Is the Evidence That ABA Works?</strong></h2>
          <p>ABA is considered evidence-based practice treatment by both the American Psychological Association and the
            U.S. Surgeon General. Over 20 studies have been published that establish that intensive and long-term
            therapy using ABA improves the quality of life for many children with autism. It also recognizes that some
            cases exist where ABA is not helpful.</p>
          {/* <figure class="w-richtext-figure-type-image w-richtext-align-fullwidth" style="max-width:1485pxpx"
            class="w-richtext-align-fullwidth w-richtext-figure-type-image">
            <div><img
                src="https://global-uploads.webflow.com/63e413c214acc4a298a98a44/63ea71649e3476bf76f7607e_ABA%206%20(1).jpg"
                loading="lazy" alt="" /></div>
          </figure> */}
          <p>Programs must be 25-40 hours a week and continue for 1-3 years to be considered long-term and intensive.
            Studies demonstrate functional improvement in language, intellectual development, daily living skill, and
            social areas of life. Some studies do not support ABA, but in many cases, these studies did not meet the
            requirements to be considered intensive and long-term.</p>
          <h2><strong>Is ABA Covered by Insurance?</strong></h2>
          <p>Whether ABA therapy is covered by insurance or not depends on where you live. Some states require insurance
            companies to cover the therapy. You should check with your carrier to see if the costs are covered. Costs
            for the program vary according to the needs of the child and the hours that will be involved to achieve the
            goals of the plan.</p>
          <p>Autism Speaks is a non-profit organization that advocates for those on the autism spectrum and provides
            help to improve healthcare coverage for medically necessary treatments, such as ABA. </p>
          <blockquote>They are an excellent resource for helping determine if your plan covers ABA and for helping you
            advocate for coverage for your child.</blockquote>
          <p>ABA therapy ends when the child meets the goals of the program, no longer meets the criteria for autism, or
            the child fails to progress over a length of time. </p>
          <p>The most important thing to keep in mind is that some children respond well to the program, while others do
            not. One of the advantages of ABA therapy is that it can be customized and can adapt to the child&#x27;s
            needs.</p>
          <p>At Total Care ABA, our team is experienced and passionate about helping your child reach their goals and
            become as independent as possible. </p>
          <p>We are leaders in the field and offer services in several states. Contact us to talk to someone about any
            special needs and concerns you have, and we will be happy to help you get started.</p>
          <h2>References</h2>
          <ol role="list">
            <li><a
                href="https://www.spectrumnews.org/features/deep-dive/controversy-autisms-common-therapy/">https://www.spectrumnews.org/features/deep-dive/controversy-autisms-common-therapy/</a>
            </li>
            <li><a
                href="https://www.healthline.com/health/aba-therapy">https://www.healthline.com/health/aba-therapy</a>
            </li>
            <li><a
                href="https://childmind.org/article/controversy-around-applied-behavior-analysis/https://www.verywellhealth.com/aba-applied-behavioral-analysis-therapy-autism-259913">https://childmind.org/article/controversy-around-applied-behavior-analysis/</a>
            </li>
            <li><a
                href="https://childmind.org/article/controversy-around-applied-behavior-analysis/https://www.verywellhealth.com/aba-applied-behavioral-analysis-therapy-autism-259913">https://www.verywellhealth.com/aba-applied-behavioral-analysis-therapy-autism-259913</a>
            </li>
          </ol>
        </div>
      </div>
      </div>
    <Footer/>
    </>
  )
}

export default WhatIsAbaTherapy