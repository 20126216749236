import React from "react";
import Page from "./Page";
import Header from "./header";
import Footer from "./footer";

function PtforNeckPain() {
  return (
    <>
        <Page title="PT for Neck Pain" />

      <Header />
      <div class="section-3 blog-post header">
        <div class="blog-post-image-overlay"></div>
        <div class="container-large-1016px blog-post hero-blog-post">
          <h1 class="display-heading article-title">
            Say Goodbye to Tech Neck: 5 Essential Physical Therapy Exercises to
            Help You Get Rid of Pain
          </h1>

          <div class="blog-post-about-wrapper">
            <div class="blog-post-about-author-wrapper">
              <div class="blog-post-about-author-content">
                <div class="blog-post-about-author-name">
                  <strong>
                    Originally written on 10/18/2021 by: Joe Zucco, P.T., DPT,
                    FAAOMPT, center manager for Select Physical Therapy. Updated
                    1/30/2023 to include new data and information. | 5 min. read
                  </strong>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="section blog-post">
        <div class="container-large-1016px blog-post">
          <div class="rich-text w-richtext">
            <p>
              For many of us, our smartphones are an indispensable part of our
              lives. We use them to stay connected to family and friends, get
              information in seconds and tap our way through our favorite music,
              news, social pages, sports stats and recipe sites. We also use
              them to keep our personal and work appointments organized. But,
              with all our tech device touchpoints each day, there’s a very real
              and painful side effect. Studies show that we’re spending too much
              time hunched over our devices, creating tech neck, also called
              “text neck”. Fortunately, physical therapy exercises can help you
              get rid of tech neck pain. In this blog post, we’ll cover what
              tech neck is and the side effects of spending too much time on
              your phone. We’ve even thrown in a surprising look at the pressure
              you’re putting on yourself that’s causing tech neck. Then we’ll
              leave you with five essential exercises to get rid of it.
            </p>

            <h2>What is tech neck?</h2>
            <p>
              Tech neck is a term used to describe the neck pain that has become
              increasingly more common due to our overuse of technology. It’s
              caused by the strain on the neck from looking down at our phones
              and tablets for long periods of time. This strain can cause pain,
              stiffness and even limited range of motion. And it’s becoming
              increasingly more common in younger generations due to the amount
              of time they spend on their devices.
            </p>
            <h2>Side effects of spending too much time on your phone</h2>
            <p>
              The yearly climb in how many hours US adults – and kids – spend on
              their mobile phones, feature phones and tablets will reach 4
              hours, 35 minutes per day in 2023. - research from Insider
              Intelligence That’s a 2.5% increase since last year! Up from 3
              hours, 42 minutes in 2018. The side effects of spending too much
              time on your phone can range from mild discomfort to severe pain
              and stiffness. The most common side effects include:
            </p>
            <ul role="list">
              <li>neck and shoulder pain</li>
              <li>headaches</li>
              <li>poor posture</li>
            </ul>

            <p>
              Other side effects can include numbness and tingling in the arms
              and hands, as well as blurred vision. It’s not something we think
              about often, but the human head is between 10 and 12 pounds.
              That’s a bowling ball! When you lean your head down, the lower the
              angle, the more pressure and strain that’s put on the head. That
              can feel like 10 bowling balls.
            </p>
            <p>
              If you’re experiencing any symptoms of tech neck, physical therapy
              can help.
            </p>
            <h2>5 essential exercises for tech neck</h2>
            <h3>Chin retraction</h3>
            <p>
              Hunched over, staring at your phone? Your head pushed out in front
              of your shoulders? This is the starting position for retraction.
            </p>

            <p>
              When you realize you’re hunched over, pull your chin backward
              while looking directly forward. You should feel a “double-chin”
              forming under your jaw.
            </p>
            <p>
              Repeat this forward/backward exercise 10 times once an hour or two
              while working or catching up on texts.
            </p>
            <h3>Trap stretch</h3>
            <p>
              Tension in the upper trapezius muscles is common. These muscles
              span the back of the neck and shoulders, working in tandem to move
              the head and shoulder blades. The trap stretch can be performed
              any time you feel tight. Just 20-30 seconds for each side of the
              neck to release tension. To stretch the right side, place your
              right hand on your waist or lower back, tilt your head to the left
              while looking back to the right. Place your left hand on top of
              your head and gently pull toward the left until you feel a
              comfortable stretch. Don’t overdo it. <br />
              Repeat on the other side.
            </p>

            <h3>Thoracic extension</h3>
            <p>
              Okay… let’s be honest. This one might look a bit odd if your
              cubicle mates walk by while you’re doing this stretch. But the
              Ah-hhh factor will be worth it! Lean forward in your chair.
              Pretend you’re smashing a pillow between your belly and thighs.
              Place your hands with fingers crossed behind your head.
              <br />
              Do this one every couple hours while sitting at your work space.
              Who knows? You might convince others to join in.{" "}
            </p>

            <h3>Prone retraction</h3>
            <p>
              The next two exercises might be better done at home… curious
              onlookers and all. Lie face down on the floor with your arms at
              your side, hands near the hips.
              <br />
              Keep your neck straight (do not look upward).
              <br />
              Lift your chin, arms and knees slightly off the floor.
              <br />
              Hold the position for 2-3 seconds and release to the floor. Repeat
              10 times for three sets.
              <br />
              With the overhead arm position, this exercise emphasizes the lower
              trapezius muscle between your shoulder blades. The prone scaption
              can be performed 2-3 days each week to promote strengthening of
              the muscles across the back of your neck, shoulders and torso.
            </p>

            <h3>Strengthening your neck posture</h3>
            <p>
              In addition to the exercises listed above, it’s important to pay
              attention to your posture while using your phone or tablet. Keep
              your chin parallel to the ground, and make sure your neck is in
              line with your spine. This will help reduce the strain on your
              neck and reduce tech neck pain.
            </p>
            <h3>Tech or text neck and how to avoid it</h3>
            <p>
              If you’re looking to avoid tech neck pain in the future, the best
              way is to limit your use of technology. Set limits on how much
              screen time you spend on your phone or have your apps running.
              Take regular breaks from looking at your screen. You can also
              invest in a stand for your phone or tablet that angles the screen
              toward you, so you don’t have to look down as much. And now, armed
              with these exercises, stay active and stretch regularly to keep
              your neck and shoulder muscles flexible to reduce the strain on
              your neck. Save the bowling balls for when you go bowling. If
              you’re experiencing neck pain, it’s important to seek medical
              attention. A physical therapist can provide the necessary
              treatments to help reduce and eliminate pain. Request an
              appointment to work with one of our movement experts near you.
            </p>

            <p>
              Reference:{" "}
              <a href="https://www.kesslerrehabilitationcenter.com/why-choose-us/blog/physical-therapy-exercises-for-tech-neck-treatment/"
              target="_blank">
                {" "}
                Kessler Rehabilitation Center{" "}
              </a>
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default PtforNeckPain;
