import React from "react";
// import "./tassets/totalcare.css";
import Fade from "react-reveal/Fade";
import Zoom from "react-reveal/Zoom";
import clogo from "../assets/clogo.png";
import mission from "./tassets/mission.png";
import values from "./tassets/values.png";
import vision from "./tassets/vision.png";
import address from "./tassets/address.png";
import mail from "./tassets/mail.png";
import phone from "./tassets/phone.png";
import ot from "./tassets/ot.png";
import earlyint from "./tassets/earlyint.png";
import intens from "./tassets/intensive.png";
import speech from "./tassets/speech.png";
import socialskill from "./tassets/socialskill.png";
import feed from "./tassets/feed.png";
import pickyeater from "./tassets/pickyeater.png";
import physcomotor from "./tassets/physcomotor.png";
import handwriting from "./tassets/handwriting.png";
import aba from "./tassets/aba.png";
import montessori from "./tassets/montessori.png";
import playart from "./tassets/playart.png";
import sensory from "./tassets/sensory.png";
import homebased from "./tassets/homebased.png";
import nutrition from "./tassets/nutrition.png";
import parents from "./tassets/parents.png";
import individualized from "./tassets/individualized.png";
import online from "./tassets/online.png";
import counselling from "./tassets/counselling.png";
import Header from "./header";
import Page from "./Page";
import physiotherapy from "./tassets/physiotherapy.png";
import { Link } from "react-router-dom";

function Cover() {
  return (
    <>
    <Page title="C-PAT Faisalabad" />
 <Header/>
    <div class="page-wrapper">
      
      <div class="section bg-gray-3 home-hero wf-section">
        <div class="hero-content">
          <div class="w-layout-grid grid-halves fullwidth-grid-halves">
            <div class="container align-center firdiv">
              {/* <div class="hero-title-label">We&#x27;re making at-home ABA therapy easy for families</div> */}
                <Zoom>
              <h1 class="display-heading-1">
             <span className="txtcolor">The{" "}</span>   
                <span class="text-danger bold ">
                  <strong className="cpatfont">C-PAT </strong>
                </span>{" "}
              </h1>
             <h2 className="h2cfp">   <span className="cfpat">(Center for Personality & Ability Therapies)</span></h2>
             <h2 className="h2cfp">  <span className="txtcolor"> wants to give normal life to every child.</span></h2>
              </Zoom>
              <p class="paragraph subtitle txtcolor">
              <Fade bottom delay={700}>
              We strive to deliver the highest quality of treatment, while simultaneously offering your family comprehensive support and guidance.
              </Fade>
              </p>
              <div class="hero-form-block w-form">
                {/* <form id="wf-form-Home-Page-Form" name="wf-form-Home-Page-Form" data-name="Home Page Form"
                redirect="/thank-you" data-redirect="/thank-you" method="get" class="form-grid-vertical">
                <div class="lead-form">
                  <div class="lead-form-row"><input type="text" class="form-input w-input" maxlength="256" name="Name"
                      data-name="Name" placeholder="Your name" id="Name" required="" /><input type="email"
                      class="form-input w-input" maxlength="256" name="Email-address" data-name="Email address"
                      placeholder="Your email address" id="Email-address" required="" /></div>
                  <div class="lead-form-row"><input type="tel" class="form-input w-input" maxlength="14"
                      name="Phone-number" data-name="Phone number" pattern="^(?:(\d{3})|\d{3})[- ]?\d{3}[- ]?\d{4}$"
                      placeholder="Your phone number" id="Phone-number" required="" /><select id="State-2"
                      name="State-2" data-name="State 2" required="" class="form-input form-select w-select">
                      <option value="">Your state</option>
                      <option value="Tennessee">Tennessee</option>
                      <option value="Indiana">Indiana</option>
                      <option value="Georgia">Georgia</option>
                      <option value="Arizona">Arizona</option>
                    </select></div><input type="submit" value="Get Started" data-wait="Please wait..."
                    class="button form-submit-button w-button" />
                </div>
              </form> */}
                {/* <div class="w-form-done">
                <div>Thank you! Your submission has been received!</div>
              </div>
              <div class="w-form-fail">
                <div>Oops! Something went wrong while submitting the form.</div>
              </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div class="section-3 insurances-accepted-section wf-section">
      <div class="container-default-3 insurances-flex-container w-container">
        <h2 class="title-3 h4-size home-v1-companies uppercase">Most insurances accepted</h2>
        <div class="home-v1-companies-wrapper with-gap"><img
            src="https://global-uploads.webflow.com/63e413c214acc42465a98a2a/64414aee980c7bacb5683d1e_medicaid.png"
            alt="medicaid" class="image-2 home-v1-company smaller-logo" /><img
            src="https://global-uploads.webflow.com/63e413c214acc42465a98a2a/64414ad9015a4b44700fa38a_aetna.svg"
            alt="aetna" class="image-2 home-v1-company smaller-logo very-small" /><img
            src="https://global-uploads.webflow.com/63e413c214acc42465a98a2a/64414ade8e643a377af8a897_cigna.svg"
            alt="cigna" class="image-2 home-v1-company smaller-logo" /><img
            src="https://global-uploads.webflow.com/63e413c214acc42465a98a2a/64414af60b106f0adeb1fa76_amerigroup.svg"
            alt="unitedhealthcare" class="image-2 home-v1-company smaller-logo" /><img
            src="https://global-uploads.webflow.com/63e413c214acc42465a98a2a/64414b0772317e042b1a3b92_bcbs.svg"
            alt="bluecrossblueshield" class="image-2 home-v1-company smaller-logo very-small" /><img
            src="https://global-uploads.webflow.com/63e413c214acc42465a98a2a/64414abfe9db3b1807da21bf_anthem.svg"
            alt="cardinalhealth" class="image-2 home-v1-company medium-logo" /></div>
      </div>
    </div> */}
      <div class="section bg-gray-3 who-we-are wf-section">
        <div class="main-container">
         
          <h2 class="large-heading section-title centered"> <Fade top>  Who We Are </Fade></h2>
          
          <p class="about-subtitle">
            <Zoom delay={900}>
            Our team of highly skilled therapists are passionate about their
            work and take great pride in seeing each child succeed.
            </Zoom>
          </p>
          <div class="formula-section">
            <div class="w-layout-grid formula-section-grid">
              <div class="formula-card">
                <div class="formula-card-img-wrapper">
                  <Fade top delay={500}>
                  <img
                    src={mission}
                    style={{ marginLeft: "20px" }}
                    className="wweare"
                    alt="mission"
                  />
                  </Fade>
                </div>
              <h4><Fade top delay={500}> Our Mission </Fade> </h4>
                <div class="text-small">
                  <Fade bottom delay={800} >
                  To focus on the highest standards in clinical integrity and
                  deliver needs-based, quality ABA therapy for children with
                  Autism Spectrum Disorder and other developmental
                  exceptionalities.
                  </Fade>
                  <br />
                </div>
              </div>
              <div class="formula-card">
                <div class="formula-card-img-wrapper">
               <Fade top delay={500}>   <img src={values}   className="wweare2"  alt="values" /></Fade>
                </div>
                <h4><Fade top delay={500}>Our Values </Fade></h4>
                <div class="text-small">
                  <Fade bottom delay={800}>
                  The families who seek our services are at the heart of
                  everything we do. We will always: 
                 <span>Promote clinical integrity and customer service </span> 
                 <span> Strive for individualization with clinical outcomes </span>
                  Cherish ethics, empathy, and respect
                  </Fade>
                  <br />
                </div>
              </div>
              <div class="formula-card">
                <div class="formula-card-img-wrapper">
               <Fade top delay={500}>   <img src={vision}   className="wweare3" alt="vision" /></Fade>
                </div>
                <h4><Fade top delay={500}>Our Vision </Fade></h4>
                <div class="text-small">
                  <Fade bottom delay={800}>
                  To create a better everyday life for children with
                  developmental exceptionalities.
                  </Fade>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div class="section wf-section">
        <div class="container-default w-container">
          <div class="features-wrapper">
            <div class="image-wrapper home-features">
              <img
                src="https://global-uploads.webflow.com/63e413c214acc42465a98a2a/63e41ccac599229e9c724ba4_welcome.png"
                alt="autism and aba therapy services"
                sizes="(max-width: 479px) 89vw, (max-width: 991px) 383.210205078125px, (max-width: 1439px) 44vw, 551.9957275390625px"
                srcset="https://global-uploads.webflow.com/63e413c214acc42465a98a2a/63e41ccac599229e9c724ba4_welcome-p-500.png 500w, https://global-uploads.webflow.com/63e413c214acc42465a98a2a/63e41ccac599229e9c724ba4_welcome-p-800.png 800w, https://global-uploads.webflow.com/63e413c214acc42465a98a2a/63e41ccac599229e9c724ba4_welcome.png 1096w"
                class="image"
              />
            </div>
            <div class="features-content">
              <h2 class="section-heading">
                We&#x27;re Your Partner, Every Step Of The Way
                <br />
              </h2>
              <div class="section-paragraph-wrapper">
                <p class="section-paragraph">
                  Waitlists, insurance coverage, different types of therapy.
                  Navigating autism care for your child is tough, but it
                  doesn&#x27;t need to be.
                  <br />
                </p>
                <p class="section-paragraph">
                  We collaborate with you at every step, from dealing with
                  insurance companies, to finding the right support, to
                  scheduling around real life. We&#x27;ll figure it out
                  together.
                  <br />
                </p>
              </div>
              <a href="/about" class="button w-button">
                About Us
              </a>
            </div>
          </div>
        </div>
      </div> */}
      {/* <div class="section wave-3rd-bg wf-section">
        <div class="container-default w-container">
          <div class="features-wrapper">
            <div class="features-content">
              <h2 class="display-heading secondary">
                Insurances We Accept
                <br />
              </h2>
              <a href="/contact-us" class="button w-button">
                Get in Touch Today
              </a>
            </div>
            <div class="insurances-right-side">
              <h2 class="section-heading text-white">
                We partner with most major insurances, enabling you to access
                premier therapy services.
                <br />
              </h2>
              <div class="insurances-we-accept">
                <div class="insurances-item">
                  <img
                    src="https://global-uploads.webflow.com/63e413c214acc42465a98a2a/63e413c214acc46a1ca98ae1_aetna.png"
                    
                    alt=""
                    class="insurances-logo"
                  />
                </div>
                <div class="insurances-item">
                  <img
                    src="https://global-uploads.webflow.com/63e413c214acc42465a98a2a/63e413c214acc436e8a98ae3_unitedhealthcare.png"
                    
                    alt=""
                    class="insurances-logo"
                  />
                </div>
                <div class="insurances-item">
                  <img
                    src="https://global-uploads.webflow.com/63e413c214acc42465a98a2a/63e413c214acc4669aa98ae2_cigna-white-300x76.png"
                    
                    alt=""
                    class="insurances-logo"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="ownermsg">
      <div class="container-medium-616px about-v1-hero larger ">
        <h1 class="title about-story">Owner's Message</h1>
        <p class="paragraph about-v1-story">I&#x27;m  <strong>Rana Saghir</strong>, the founder and CEO of C-PAT (Center for Personality and Ability Therapies). My
                mission is to  change the lives of thousands of families across the Pakistan. who are challenged with the
                autism spectrum.<br />‍<br />Our highly skilled and licensed therapists are passionate about providing
                personalized support for children with autism and take great pride in seeing each child succeed. Since
                our inception, we have been instrumental in bringing out our clients’ strengths to reach their
                potential.<br />‍<br />We&#x27;re <em>totally</em> committed to making a difference in the everyday
                lives of the families we care for.<br /></p>
      </div>
      </div>
      <div class="section bg-gray-3 wf-section"  id="page-1" >
        <div class="main-container">
          <h2 class="large-heading section-title centered"><Fade top>Our Services </Fade></h2>
          <div class="formula-section">
            <div class="w-layout-grid formula-section-grid">
         <Fade bottom delay={500}>
              <Link to="/ot">
                <div class="formula-card hvr">
                  <div class="formula-card-img-wrapper">
                    <img src={ot}  alt="Occupational Therapy" />
                  </div>
                  <h4>Occupational Therapy</h4>
                  <div class="text-small">
                    Techniques that improve the fine and motor skills of
                    children and preparing them to be more independent and
                    self-reliant.
                    <br />
                  </div>
                </div>
              </Link>
              </Fade>
              <Fade bottom delay={650}>
              <Link to="/aba">
              <div class="formula-card hvr">
                <div class="formula-card-img-wrapper">
                  <img
                    src={aba}
                    
                    alt="Behavior Therapy (ABA)"
                  />
                </div>
                <h4>Behavior Therapy(ABA)</h4>
                <div class="text-small">
                  Extensive one-on-one sessions that include behavioral
                  modification and skill development in children with
                  neuro-developmental disorder.
                  <br />
                </div>
              </div>
              </Link>
              </Fade>
              <Fade bottom delay={500}>
              <Link to="/speech">
              <div class="formula-card hvr">
                <div class="formula-card-img-wrapper">
                  <img
                  src={speech}
                    
                    alt="Speech and Language Therapy"
                  />
                </div>
                <h4>Speech and Language Therapy</h4>
                <div class="text-small">
                  Dynamic techniques that empower children with speech and
                  language disorders with the power to communicate effectively.
                  <br />
                </div>
              </div>
              </Link>
              </Fade>
             
              <Fade top delay={500}>
              <Link to="/pt">
              <div class="formula-card hvr">
                <div class="formula-card-img-wrapper">
                  <img src={physiotherapy}  alt="Phsiotherapy" />
                </div>
                <h4>Physiotherapy</h4>
                <div class="text-small">
                  Physiotherapy exercises aimed to improving the strength, gross
                  motor skills, coordination, flexibility, balance and in
                  children
                  <br />
                </div>
              </div>
              </Link>
              </Fade>
              <Fade top delay={650}>
              <Link to="/nutrition">
              <div class="formula-card hvr">
                <div class="formula-card-img-wrapper">
                  <img
                    src={nutrition}
                    
                    alt="Nutrition"
                  />
                </div>
                <h4>Medical Nutrition Therapy</h4>
                <div class="text-small">
                  Our nutritionist proved nutritional advice, customized diet,
                  online follow-ups, weekly evolution, nutrition assessment and
                  weight management. <br />
                </div>
              </div>
              </Link>
              </Fade>

              <Fade top delay={650}>
              <Link to="/montessori">
              <div class="formula-card hvr">
                <div class="formula-card-img-wrapper">
                  <img
                    src={montessori}
                    
                    alt="Montessori"
                  />
                </div>
                <h4>Montessori</h4>
                <div class="text-small">
                  Specialized school programs that your child’s social
                  interaction with peers, academic and writing competencies.{" "}
                  <br />
                </div>
              </div>
              </Link>
              </Fade>

              <Fade top delay={650}>
              <Link to="/parentstrain">
              <div class="formula-card hvr parental">
                <div class="formula-card-img-wrapper">
                  <img
                    src={parents}
                    
                    alt="Parental Training"
                  />
                </div>
                <h4>Parental Training</h4>
                <div class="text-small">
                  Provide parents with training, information, tools and
                  resources that assists family’s in preparing their children to
                  achieve positive postsecondary outcomes that lead to lives
                  that are as productive and independent as possible. <br />
                </div>
              </div>
              </Link>
              </Fade>
            
              
            </div>
          </div>
        </div>
      </div>
     
      
      <div class="section bg-gray-3 skillsup ownermsg">
      <div class="main-container">
        <h2 class="large-heading section-title centered ">Skilled support,<br />every step of the way</h2>
        <br></br>
      <br></br>
      <br></br>

        <div class="formula-section">
          <div class="w-layout-grid formula-section-grid four-columns">
            <div class="formula-card">
              <div class="formula-image-wrapper">
                <div class="formula-number _1">.01</div><img
                  src="https://global-uploads.webflow.com/63b83240ef2e97f28b012a0b/64eca3d50ba9974ec9639a2e_Home-support.svg"
                  alt="step 1 Observe" class="icon-vertical" />
              </div>
              <h4>We Observe</h4>
              <div class="text-small">Our BCBAs Therapists monitor your child’s behaviors,
                pinpointing triggers and noting outcomes.</div>
            </div>
            <div class="formula-card">
              <div class="formula-image-wrapper">
                <div class="formula-number _2">.02</div><img
                  src="https://global-uploads.webflow.com/63b83240ef2e97f28b012a0b/64eca3b6f36e97dd7c1ec1f3_Home-skill.svg"
                  alt="step 2 we support" class="icon-vertical" />
              </div>
              <h4>We Support</h4>
              <div class="text-small">Keeping to an individualized treatment plan, our Therapists work one-on-one with your
                child to develop healthy behaviors and skills.</div>
            </div>
            <div class="formula-card">
              <div class="formula-image-wrapper">
                <div class="formula-number _3">.03</div><img
                  src="https://global-uploads.webflow.com/63b83240ef2e97f28b012a0b/64eca3cffeae94b9612b1864_Home-success.svg"
                  alt="step 3 we guide" class="icon-vertical" />
              </div>
              <h4>We Guide</h4>
              <div class="text-small">We equip parents and other caregivers with the knowledge and tools needed to help
                your child integrate their new skills.</div>
            </div>
            <div class="formula-card">
              <div class="formula-image-wrapper">
                <div class="formula-number _3">.04</div><img
                  src="https://global-uploads.webflow.com/63b83240ef2e97f28b012a0b/64eca3d50ba9974ec9639a2e_Home-support.svg"
                  alt="step 4 we celebrate" class="icon-vertical" />
              </div>
              <h4>We Celebrate</h4>
              <div class="text-small">We cheer your little heroes on, encouraging them to overcome every obstacle and
                celebrating with them as they achieve their goals.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br></br>
      <br></br>
      <br></br>
      <br></br>
      <div class="footer wf-section">
        <div class="main-container">
          <div class="footer-top-row">
            <div class="footer-logo-and-menus">
              <a
                href="/"
                aria-current="page"
                class="footer-logo w-inline-block w--current"
              >
                <img
                  src={clogo}
                  style={{ width: "145px" }}
                  sizes="100px"
                  srcset=""
                  alt=""
                  class="logo-footer"
                />
              </a>
              {/* <div class="w-layout-grid menu-grid-vertical">
                <a
                  href="/"
                  aria-current="page"
                  class="hover-link text-white w--current"
                >
                  Home
                </a>
                <a href="/about" class="hover-link text-white">
                  About
                </a>
                <a href="/services" class="hover-link text-white">
                  Services
                </a>
                <a href="/careers" class="hover-link text-white">
                  Careers
                </a>
                <a href="/insurances" class="hover-link text-white">
                  Insurances
                </a>
                <a href="/team" class="hover-link text-white">
                  Team
                </a>
              </div> */}
              {/* <div class="w-layout-grid menu-grid-vertical">
                <a href="/contact-us" class="hover-link text-white">
                  Contact Us
                </a>
                <a href="/locations" class="hover-link text-white">
                  Locations
                </a>
                <a href="/statistics" class="hover-link text-white">
                  Research
                </a>
                <a href="/autism" class="hover-link text-white">
                  Learning Center
                </a>
                <a href="/refer-a-patient" class="hover-link text-white">
                  Refer To us
                </a>
              </div> */}
              <div class="w-layout-grid menu-grid-vertical contact-links">
                <div
                  id="w-node-f1264d15-80c8-4991-c7b7-33e6975f21b9-6dcb7750"
                  class="footer-link-with-icon"
                >
                  <img src={phone}   alt="" class="footer-icon" />
                  <a href="tel: +923011605000" class="hover-link text-white">
                    +923011605000
                  </a>
                </div>


                <div
                  id="w-node-f1264d15-80c8-4991-c7b7-33e6975f21b9-6dcb7750"
                  class="footer-link-with-icon"
                >
                  <img src={phone}   alt="" class="footer-icon" />
                  <a href="tel: +9665330679337" class="hover-link text-white">
                    +9665330679337  
                  </a>
                  
                  <span class="hover-link text-white" style={{width:"150px"}}> (For WhatsApp)</span> 
                </div>

                {/* <div
                  id="w-node-_9064a1db-1b1b-0fc6-d66c-758b86ea184b-6dcb7750"
                  class="footer-link-with-icon"
                >
                  <img
                    src="https://global-uploads.webflow.com/63e413c214acc42465a98a2a/63e413c214acc47e46a98b58_fax%201.svg"
                    
                    alt=""
                    class="footer-icon"
                  />
                  <a href="tel:(404)400-5003" class="hover-link text-white">
                    (404) 400-5003
                  </a>
                </div> */}
                <div
                  id="w-node-_3e76cf14-9b6d-4119-3663-4dc656f9b0df-6dcb7750"
                  class="footer-link-with-icon"
                >
                  <img src={mail}  alt="" class="footer-icon" />
                  <a
                    href="mailto: info@cpat.com.pk"
                    class="hover-link text-white"
                  >
                    info@cpat.com.pk
                  </a>
                </div>

                <div
                  id="w-node-f1264d15-80c8-4991-c7b7-33e6975f21b9-6dcb7750"
                  class="footer-link-with-icon"
                >
                  <img
                    src={address}
                    // style={{color:"white"}}
                    
                    alt=""
                    class="footer-icon"
                  />
                  <a
                    href="https://g.co/kgs/4LJ1YN"
                    class="hover-link text-white"
                  >
                    Address: Imtiaz Shaheed Road, near Prime Care Hospital, Block
                    X, Madina Town, Faisalabad, Punjab, Pakistan.
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="footer-bottom-row footer-3-bottom-row">
            <div class="footer-bottom-links">
              <a href="/privacy-policy" class="row-link">
                Privacy Policy
              </a>
              <div>
                C-PAT © <span id="current-year-footer">2024.</span> All rights
                reserved.
                <strong>
                  <br />
                </strong>
              </div>
            </div>
            <div class="social-links">
              <a
                href="https://www.facebook.com/info.cpat"
                target="_blank"
                class="social-link hover-link w-inline-block"
              >
                <img
                  src="https://global-uploads.webflow.com/63e413c214acc42465a98a2a/63e413c214acc47c5ea98a72_facebook.svg"
                  alt=""
                  class="social-link-image"
                />
              </a>
              <a
                href=""
                target="_blank"
                class="social-link hover-link w-inline-block"
              >
                <img
                  src="https://global-uploads.webflow.com/63e413c214acc42465a98a2a/63e413c214acc4cd4ba98a71_linkedin.svg"
                  alt=""
                  class="social-link-image"
                />
              </a>
              <a
                href=""
                target="_blank"
                class="social-link hover-link w-inline-block"
              >
                <img
                  src="https://global-uploads.webflow.com/63e413c214acc42465a98a2a/63e413c214acc475dba98adf_instagram.png"
                  alt=""
                  class="social-link-image"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div
        data-w-id="f24a4ab8-14fc-b74c-63f4-60b604a9b094"
        class="back-to-top-button"
      >
        <a href="#" class="w-inline-block">
          <img
            src="https://global-uploads.webflow.com/63e413c214acc42465a98a2a/63e413c214acc438c5a98b84_up-arrow.png"
            
            alt=""
            class="back-to-top-icon"
          />
        </a>
      </div>
    </div>
    </>
  );
}

export default Cover;
