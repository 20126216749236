import React from "react";
import "./tassets/totalcare.css";
import clogo from "../assets/clogo.png";
import nutritionserv from "./tassets/nutritionserv.jpg";
import ann from "./tassets/ann.jpg";
import individualize from "./tassets/individualize.jpg";
import sn from "./tassets/sn.jpg";
import mhnt from "./tassets/mhnt.jpg";

import Header from "./header";
import Footer from "./footer";
import Fade from "react-reveal/Fade";
import Zoom from "react-reveal/Zoom";
import Page from "./Page";
function Nutrition() {
  return (
    <>
        <Page title="Medical Nutrition Therapy" />

      <Header />
      <div className="servicediv">
      <Fade left delay={700}>
        <h4 className="oth">Medical Nutrition Therapy</h4>
        <br></br> <br></br>
      </Fade>
      <div class="otit">
        <div className="otpicdiv">
          <Zoom delay={500}>
            <img className="otpic" src={nutritionserv} alt="Medical Nutrition Therapy"></img>
          </Zoom>
        </div>
        <div class="text-small ottext ">
          <Fade bottom delay={300}>
          It is a specialized healthcare intervention that uses diet and nutritional counseling to manage or treat medical conditions, promoting overall health and well-being through tailored dietary plans.
            These services are typically provided by nutritionists who have received specialized training and
            education in the field of nutrition.
            <br></br>
          
          </Fade>
        </div>
      </div>
      <br></br>
      <div class="section less-padding">
      <div class="container-default">
        <h2 class="title about-story">Our Services in Medical Nutrition Therapy</h2>
        <div class="services-cards">
          <div class="services-card"><img
              src={ann}
              
              sizes="(max-width: 479px) 100vw, (max-width: 767px) 75vw, (max-width: 991px) 46vw, (max-width: 1439px) 30vw, 380px"
              
              alt="Assessing Nutrition Needs" class="services-card-img" />
            <div class="services-card-content">
              <h2 class="services-card-title">Assessing Nutrition Needs</h2>
              <p class="services-card-subtitle">It involves evaluating an individual’s dietary requirements based on factors like age, activity level, and health status to create a tailored and balanced nutrition plan for optimal health.<br /></p>
            </div>
          </div>
          <div class="services-card"><img
              src={individualize}
              
              sizes="(max-width: 479px) 100vw, (max-width: 767px) 75vw, (max-width: 991px) 46vw, (max-width: 1439px) 30vw, 380px"
             
              alt="Tailored Health Education" class="services-card-img" />
            <div class="services-card-content">
              <h2 class="services-card-title">Tailored Health Education</h2>
              <p class="services-card-subtitle">It involves customizing information and advice to an individual’s specific needs, empowering them to make informed decisions for a healthier lifestyle and better overall well-being.<br /></p>
            </div>
          </div>
          <div class="services-card"><img
              src={sn}
              
              sizes="(max-width: 479px) 100vw, (max-width: 767px) 75vw, (max-width: 991px) 46vw, (max-width: 1439px) 30vw, 380px"
              
              alt="Sports Nutrition" class="services-card-img" />
            <div class="services-card-content">
              <h2 class="services-card-title">Sports Nutrition</h2>
              <p class="services-card-subtitle">It is encompassing a variety of therapeutic tools like heat, cold, ultrasound, and electrical stimulation used in healthcare to alleviate pain, promote healing, and enhance rehabilitation.<br /></p>
            </div>
          </div>
          <div class="services-card gam"><img
              src={mhnt}
              
              sizes="(max-width: 479px) 100vw, (max-width: 767px) 75vw, (max-width: 991px) 46vw, (max-width: 1439px) 30vw, 380px"
              
              alt="Mental Health and Nutrition Therapy" class="services-card-img" />
            <div class="services-card-content">
              <h2 class="services-card-title">Mental Health and Nutrition Therapy</h2>
              <p class="services-card-subtitle"> it is a holistic approach that recognizes the powerful connection between diet and mental well-being. It leverages dietary choices to promote mental health and emotional balance.<br /></p>
            </div>
          </div>
         
        </div>
      </div>
      </div>
      <br></br>
      <br></br>
      <br></br> <br></br>
      <br></br>
      <br></br>
      </div>
      <Footer />
    </>
  );
}

export default Nutrition;
