import React from "react";
import "./tassets/totalcare.css";
import Page from "./Page";
import Header from "./header";
import Footer from "./footer";

function TipsforSpeechTherapySession() {
  return (
    <>
        <Page title="Tips for Speech Therapy Session" />

      <Header />
      <div class="section-3 blog-post header">
      <div class="blog-post-image-overlay"></div>
      <div class="container-large-1016px blog-post hero-blog-post">
        <h1 class="display-heading article-title"> 5 Tips for a Fun & Productive Speech Therapy Session</h1>
      
        <div class="blog-post-about-wrapper">
          <div class="blog-post-about-author-wrapper">
           
            <div class="blog-post-about-author-content"><a 
                target="_blank" class="blog-post-about-author-link w-inline-block">
                <div class="blog-post-about-author-name"><strong>By: Sarah Michael, MA, CCC-SLP</strong></div>
              </a>
            
            </div>
          </div>
        </div>
      </div>
    </div>

      <div class="section blog-post">
        <div class="container-large-1016px blog-post">
          <div class="rich-text w-richtext">
            <h2>Set up your environment:</h2>
            <p>
            Put things out of reach.  This allows you to create opportunities for communication as your child has to request what they want.  Make sure that your child has a way to communicate their request.  This could be pointing, using a sign, using a low-tech or high-tech alternative/augmentative communication method, using a word approximation, using a single word, or using a complete sentence.  Talk to your speech-language pathologist about what would work best for your child at this moment in time.      </p>
<h2>Have fun:</h2>
            <p>This is one of the most important elements of a successful speech-language session.  Learning occurs when the brain lights up, and the brain lights up when it is having fun.  You also want to reduce the amount of time that you are trying to entice your child into engaging.  If you have a fun activity, or more importantly, you yourself are fun, you do not have to work as hard to engage your child.  Use activities that you know your child will enjoy.  </p>
  <h2>Attainable models:  </h2>
  <p>Provide models that are attainable for your child to attempt.  If your child is not yet producing any speech sounds, “I want bubbles” is probably a bit too challenging.  Instead, a model of “buh” is great.  Some children are “risk averse”, and know that saying a full sentence is just out of their reach right now.  If they realize that the goal can be much simpler, they may be more willing to make an attempt.  Provide models of word approximations for common functional words (e.g., “wa” for water).  

</p>
           <h2>Repetitions:</h2> 
           <p>Just like exercising at the gym, just one repetition is not going to do much.  So, it’s important that we think about how to get many repetitions out of an activity.  This could mean producing a target before taking a turn in a game, or receiving a piece of snack, or before performing a certain action (e.g., tickling, etc.).  Think about what is rewarding or motivating for your child.  </p>
    <h2>Keep quiet:</h2>
    <p>It is extremely important that there are periods of quiet and wait time throughout the speech-language session.  Children need an opportunity to fill in the silence.  They also often need more processing time to use their skills, so it is important that we provide extended periods of quiet.  It is tempting to fill in the quiet by prompting your child to talk (e.g., “Say ball! Say ball!  Say ball!), but remember that if you are talking, they will not.  Instead, hold the ball by your face, out of reach, and look at them with wide eyes and an expectant face.  Provide 1-3 happy models, “Ball!”, and again, look at them with an expectant face.  Wait 5-10 seconds, then give the ball.  This way, you provided clear models and gave them ample time to attempt their own target.    </p>
            <p>
              Reference:{" "}
              <a href="www.nationaltherapycenter.com/uncategorized/shark-week-5-tips-for-a-fun-productive-speech-therapy-session"
              target="_blank">
                {" "}
                National Therapy Center{" "}
              </a>
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default TipsforSpeechTherapySession;
