import React from "react";
import "./tassets/totalcare.css";
import clogo from "../assets/clogo.png";
import salt from "./tassets/salt.jpg";
import at from "./tassets/at.jpg";
import phonet from "./tassets/phonet.jpg";
import vt from "./tassets/vt.jpg";
import ft from "./tassets/ft.jpg";
import st from "./tassets/st.jpg";
import cct from "./tassets/cct.jpg";
import Header from "./header";
import Footer from "./footer";
import Fade from "react-reveal/Fade";
import Zoom from "react-reveal/Zoom";
import Page from "./Page";
function Speech() {
  return (
    <>
        <Page title="Speech and Language Therapy" />

      <Header />
      <div className="servicediv">
      <Fade left delay={700}>
        <h4 className="oth">Speech and Language Therapy</h4>
        <br></br> <br></br>
      </Fade>
      <div class="otit">
        <div className="otpicdiv">
          <Zoom delay={500}>
            <img className="otpic" src={salt} alt="speech and language therapy"></img>
          </Zoom>
        </div>
        <div class="text-small ottext ">
          <Fade bottom delay={300}>
          A specialized field of healthcare aiming to improve communication and speech disorders, enhancing individuals’ ability to express themselves effectively.
            Speech-language pathologists (SLPs) work with individuals of all
            ages who have difficulties with speech, language, voice, fluency,
            cognition, and swallowing.
            <br></br>
          </Fade>
          </div>
      </div>
    <div class="section less-padding">
      <div class="container-default">
        <h2 class="title about-story">Our Services in Speech and Language Therapy</h2>
        <div class="services-cards">
          <div class="services-card"><img
              src={at}
              
              sizes="(max-width: 479px) 100vw, (max-width: 767px) 75vw, (max-width: 991px) 46vw, (max-width: 1439px) 30vw, 380px"
              
              alt="Articulation Therapy" class="services-card-img" />
            <div class="services-card-content">
              <h2 class="services-card-title">Articulation Therapy</h2>
              <p class="services-card-subtitle">Articulation therapy refers to the way speech sounds are produced, including the movements of the tongue, lips, and vocal cords to form specific sounds. Articulation therapy is a type of speech therapy aimed at improving a person’s ability to correctly pronounce sounds, words, and sentences. It’s often used to treat speech disorders, such as lisps or difficulty with specific sounds.<br /></p>
            </div>
          </div>
          <div class="services-card"><img
              src={phonet}
              
              sizes="(max-width: 479px) 100vw, (max-width: 767px) 75vw, (max-width: 991px) 46vw, (max-width: 1439px) 30vw, 380px"
             
              alt="Phone Therapy" class="services-card-img" />
            <div class="services-card-content">
              <h2 class="services-card-title">Phone Therapy</h2>
              <p class="services-card-subtitle">Phone therapy, on the other hand, typically refers to teletherapy or remote speech therapy sessions conducted over the phone or via video calls. This approach allows individuals to receive articulation therapy services from a speech-language pathologist without the need for in-person appointments, making it more accessible and convenient for many people.<br /></p>
            </div>
          </div>
          <div class="services-card"><img
              src={vt}
              
              sizes="(max-width: 479px) 100vw, (max-width: 767px) 75vw, (max-width: 991px) 46vw, (max-width: 1439px) 30vw, 380px"
              
              alt="Voice Therapy" class="services-card-img" />
            <div class="services-card-content">
              <h2 class="services-card-title">Voice Therapy</h2>
              <p class="services-card-subtitle">Voice therapy is a specialized form of therapy aimed at improving one’s vocal function, quality, and communication skills. It’s often used to address voice disorders, such as hoarseness or speech difficulties, through exercises, techniques, and sometimes technology to enhance vocal performance and restore or optimize voice health.<br /></p>
            </div>
          </div>
        
          <div class="services-card"><img
              src={ft}
              
              sizes="(max-width: 479px) 100vw, (max-width: 767px) 75vw, (max-width: 991px) 46vw, (max-width: 1439px) 30vw, 380px"
              
              alt="Fluency Therapy" class="services-card-img" />
            <div class="services-card-content">
              <h2 class="services-card-title">Fluency Therapy</h2>
              <p class="services-card-subtitle">Fluency therapy is a type of speech therapy designed to help individuals who experience difficulties with speech fluency, such as stuttering. It involves various techniques and exercises to improve the smoothness and natural flow of speech, enhancing communication skills and confidence.<br /></p>
            </div>
          </div>
          <div class="services-card"><img
              src={cct}
              
              sizes="(max-width: 479px) 100vw, (max-width: 767px) 75vw, (max-width: 991px) 46vw, (max-width: 1439px) 30vw, 380px"
             
              alt="Cognitive Communication Therapy" class="services-card-img" />
            <div class="services-card-content">
              <h2 class="services-card-title">Cognitive Communication Therapy (CCT) </h2>
              <p class="services-card-subtitle">Is a therapeutic approach aimed at improving communication skills in individuals who have experienced difficulties due to cognitive impairments, such as stroke, traumatic brain injury, or neurological conditions. It focuses on enhancing cognitive functions like attention, memory, problem-solving, and language comprehension to help individuals regain effective communication abilities and better engage in daily life interactions.<br /></p>
            </div>
          </div>
          <div class="services-card"><img
              src={st}
              
              sizes="(max-width: 479px) 100vw, (max-width: 767px) 75vw, (max-width: 991px) 46vw, (max-width: 1439px) 30vw, 380px"
              
              alt="Swallowing Therapy" class="services-card-img" />
            <div class="services-card-content">
              <h2 class="services-card-title">Swallowing Therapy</h2>
              <p class="services-card-subtitle">Swallowing therapy is also known as dysphagia therapy, is a specialized form of rehabilitation aimed at improving difficulties in swallowing. It involves exercises, techniques, and dietary adjustments to enhance a person’s ability to safely and effectively swallow food and liquids, often administered by speech-language pathologists or occupational therapists.<br /></p>
            </div>
          </div>
         
        </div>
      </div>
    </div>
          
      
      <br></br>
     
      <br></br>
      <br></br>
      <br></br> <br></br>
      <br></br>
      <br></br>
      </div>
      <Footer />
    </>
  );
}

export default Speech;
