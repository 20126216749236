import React from "react";
import "./tassets/totalcare.css";
import clogo from "../assets/clogo.png";
import parentstraining from "./tassets/parentstraining.jpg";
import pc from "./tassets/pc.jpg";
import hot from "./tassets/hot.jpg";
import ebt from "./tassets/ebt.jpg";
import ohbs from "./tassets/ohbs.jpg";
import Header from "./header";
import Footer from "./footer";
import Fade from "react-reveal/Fade";
import Zoom from "react-reveal/Zoom";
import Page from "./Page";
function Parentstrain() {
  return (
    <>
        <Page title="Parental Training" />

      <Header />
      <div className="servicediv">
      <Fade left delay={700}>
        <h4 className="oth">Parental Training</h4>
        <br></br> <br></br>
      </Fade>
      <div class="otit">
        <div className="otpicdiv">
          <Zoom delay={500}>
            <img className="otpic" src={parentstraining} alt="parental training"></img>
          </Zoom>
        </div>
        <div class="text-small ottext ">
          <Fade bottom delay={300}>
            Parent training refers to educational programs or interventions
            designed to provide parents with knowledge, skills, and strategies
            to effectively support their child's development, well-being, and
            specific needs. Parent training programs are commonly used in
            various contexts, including special education, behavioral
            interventions, and therapeutic interventions for children with
            developmental, behavioral, or emotional challenges.
            <br></br>
            
          </Fade>
        </div>
      </div>
      <br></br>
   
      <div class="section less-padding">
      <div class="container-default">
        <h2 class="title about-story">Our Services in Parental Training</h2>
        <div class="services-cards">
          <div class="services-card"><img
              src={pc}
              
              sizes="(max-width: 479px) 100vw, (max-width: 767px) 75vw, (max-width: 991px) 46vw, (max-width: 1439px) 30vw, 380px"
              
              alt="Parent’s Counseling" class="services-card-img" />
            <div class="services-card-content">
              <h2 class="services-card-title">Parent’s Counseling</h2>
              <p class="services-card-subtitle">It is a supportive service that helps parents navigate challenges and improve their parenting skills. It fosters healthier parent-child relationships and addresses various family-related issues.<br /></p>
            </div>
          </div>
          <div class="services-card"><img
              src={hot}
              
              sizes="(max-width: 479px) 100vw, (max-width: 767px) 75vw, (max-width: 991px) 46vw, (max-width: 1439px) 30vw, 380px"
             
              alt="Hands-on Training" class="services-card-img" />
            <div class="services-card-content">
              <h2 class="services-card-title">Hands-on Training</h2>
              <p class="services-card-subtitle">It equips caregivers with practical skills and knowledge for effective parenting. It focuses on nurturing child development, fostering a healthy parent-child relationship, and addressing common parenting challenges.<br /></p>
            </div>
          </div>
          <div class="services-card"><img
              src={ebt}
              
              sizes="(max-width: 479px) 100vw, (max-width: 767px) 75vw, (max-width: 991px) 46vw, (max-width: 1439px) 30vw, 380px"
              
              alt="Evidence-based Techniques" class="services-card-img" />
            <div class="services-card-content">
              <h2 class="services-card-title">Evidence-based Techniques</h2>
              <p class="services-card-subtitle">These are proven strategies grounded in scientific research that help caregivers raise children effectively, fostering their physical, emotional, and cognitive development in a supportive and informed manner.<br /></p>
            </div>
          </div>
          <div class="services-card gam"><img
              src={ohbs}
              
              sizes="(max-width: 479px) 100vw, (max-width: 767px) 75vw, (max-width: 991px) 46vw, (max-width: 1439px) 30vw, 380px"
              
              alt="Online Home-based Sessions" class="services-card-img" />
            <div class="services-card-content">
              <h2 class="services-card-title">Online Home-based Sessions</h2>
              <p class="services-card-subtitle">It provides convenient access to various services, from therapy and fitness training to education and work, all conducted remotely from the comfort of one’s home using digital platforms.<br /></p>
            </div>
          </div>
         
        </div>
      </div>
      </div>
      <br></br>
      <br></br>
      <br></br> <br></br>
      <br></br>
      <br></br>
      </div>
      <Footer />
    </>
  );
}

export default Parentstrain;
