import React from 'react'
import Header from "./header";
import Footer from "./footer";
import ahmed from "./tassets/sirahmed.jpg"
import nutritionserv from "./tassets/nutritionserv.jpg";
import ann from "./tassets/ann.jpg";
import individualize from "./tassets/individualize.jpg";
import sn from "./tassets/sn.jpg";
import mhnt from "./tassets/mhnt.jpg";
import Page from './Page';


const AboutUs = () => {
  return (
<>
<Page title="ABOUT US" />
<Header/>
<div class="section-3 about-hero no-bg">
      <div class="container-medium-616px about-v1-hero larger">
        <h1 class="title-4 neutral-100 about-v1-hero">About Us &amp; Our Story</h1>
        <p class="paragraph neutral-100 about-v1-hero">Welcome to C-PAT where every child's well-being is our top priority. We are a leading pediatric therapeutic center dedicated to providing comprehensive care and support to children of all ages and abilities.</p>
      </div>
    </div>
   <br/>
   <br/>
   <br/>
   <br/>
   <br/>
   <br/>
   <br/>
    <div class="container-default">
      <div class="about-cards">
        <div class="about-card">
          <img
            src="https://global-uploads.webflow.com/63b83240ef2e97f28b012a0b/63c6f2c16e080b2989c2955c_know.png"
            loading="lazy" sizes="(max-width: 479px) 100vw, 140px"
            srcset="https://global-uploads.webflow.com/63b83240ef2e97f28b012a0b/63c6f2c16e080b2989c2955c_know-p-500.png 500w, https://global-uploads.webflow.com/63b83240ef2e97f28b012a0b/63c6f2c16e080b2989c2955c_know.png 585w"
            alt="family with autism" class="about-card-img" />
          <div class="about-card-content">
            <h2 class="about-card-title">We know</h2>
            <p class="about-card-subtitle">the challenges faced by children with ASD and their parents.<br /></p>
          </div>
        </div>
        <div class="about-card"><img
            src="https://global-uploads.webflow.com/63b83240ef2e97f28b012a0b/63c6f2c1910e8a155f0013ff_believe.png"
            loading="lazy" sizes="(max-width: 479px) 100vw, 140px"
            srcset="https://global-uploads.webflow.com/63b83240ef2e97f28b012a0b/63c6f2c1910e8a155f0013ff_believe-p-500.png 500w, https://global-uploads.webflow.com/63b83240ef2e97f28b012a0b/63c6f2c1910e8a155f0013ff_believe.png 585w"
            alt="autistic child playing with therapist" class="about-card-img" />
          <div class="about-card-content">
            <h2 class="about-card-title">We believe</h2>
            <p class="about-card-subtitle">every child has unique strengths and talents waiting to be discovered.<br />
            </p>
          </div>
        </div>
        <div class="about-card"><img
            src="https://global-uploads.webflow.com/63b83240ef2e97f28b012a0b/63c6f2c1490d8da9ae61381b_strive.png"
            loading="lazy" sizes="(max-width: 479px) 100vw, 140px"
            srcset="https://global-uploads.webflow.com/63b83240ef2e97f28b012a0b/63c6f2c1490d8da9ae61381b_strive-p-500.png 500w, https://global-uploads.webflow.com/63b83240ef2e97f28b012a0b/63c6f2c1490d8da9ae61381b_strive.png 585w"
            alt="therapist helping kids with autism" class="about-card-img" />
          <div class="about-card-content">
            <h2 class="about-card-title">We strive</h2>
            <p class="about-card-subtitle">to unlock every child’s potential to help them taste success.<br /></p>
          </div>
        </div>
      </div>
    </div>
    {/* <div class="container-default"></div> */}
    {/* <div id="about-v1-story" class="section less-padding secondary">
      <div class="container-default w-container">
        <div class="about-story-wrapper founder-story">
          <div class="split-content about-v1-story-right">
            <div class="image-wrapper founder-img-wrapper"><img
                src="https://global-uploads.webflow.com/63e413c214acc42465a98a2a/63e413c214acc465fba98b83_ruben.jpeg"
                alt="therapist with autistic child" class="image-2 about-v1-mission founder-photo" /></div>
          </div>
          <div class="split-content">
            <div class="about-story-content">
              <h2 class="title about-story">Meet The Founder</h2>
              <p class="paragraph about-v1-story">I&#x27;m  <strong>Muhammad Sagheer Hussain</strong>, the founder and CEO of C-PAT (Center for Personality and Ability Therapies). My
                mission is to  change the lives of millions of families across the Pakistan. who are challenged with the
                autism spectrum.<br />‍<br />Our highly skilled and licensed therapists are passionate about providing
                personalized support for children with autism and take great pride in seeing each child succeed. Since
                our inception, we have been instrumental in bringing out our clients’ strengths to reach their
                potential.<br />‍<br />We&#x27;re <em>totally</em> committed to making a difference in the everyday
                lives of the families we care for.<br /></p>
            </div>
          </div>
        </div>
      </div>
    </div> */}
    <div id="about-v1-story" class="section less-padding">
      <div class="">
        <div class="">
          <div class="">
            <div class="container-medium-616px about-v1-hero larger">
              <h2 class="title-4 neutral-100 about-v1-hero">Our Mission Statement</h2>
              <p class="paragraph about-v1-story">At C-PAT our mission is clear to nurture the potential within every child, helping them overcome challenges and achieve their fullest potential. We believe that every child is unique and deserves individualized care, attention, and support.<br /></p>
            </div>
          </div>
        
        </div>
      </div>
    </div>
    <div id="about-v1-story" class="section less-padding">
    <div class="container-medium-616px about-v1-hero larger">
    <h1 class="title-4 neutral-100 about-v1-hero">Our Team</h1>
    <p class="paragraph about-v1-story">Our team of dedicated professionals is at the heart of what we do. Our multidisciplinary staff includes experienced pediatric therapists, psychologists, special educators, and support staff who are passionate about working with children. We take pride in our team's expertise and commitment to staying current with the latest advancements in pediatric therapy.<br /> <br/></p>
   
    <h2 class="title about-story">Holistic Approach</h2>
    <p class="paragraph about-v1-story">We understand that every child is different, and their needs are unique. That's why we take a holistic approach to pediatric therapy. We offer a wide range of services, including speech therapy, occupational therapy, physical therapy, behavior therapy and  psychological counseling, all under one roof. This ensures that we can address the diverse needs of each child in a coordinated and comprehensive manner.<br /> <br/></p>
   
    <h2 class="title about-story">Family-Centered Care</h2>
    <p class="paragraph about-v1-story">At C-PAT we believe that families play an integral role in a child's development and progress. We work closely with parents and caregivers to create personalized treatment plans and provide guidance and support every step of the way. We consider our center to be an extension of your family, and we are here to walk this journey with you.<br /> <br/></p>
   
    </div>

      </div>
    <Footer/>
</>
  )
}

export default AboutUs