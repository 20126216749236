import React, { useEffect } from 'react';
import "./tassets/totalcare.css";
import clogo from "../assets/clogo.png";
import { Link } from 'react-router-dom';
import menuicon from "./tassets/menuicon.svg";
import crossmenu from "./tassets/crossmenu.svg";
import ExternalScripts from './ExternalScripts';
// import "./jquery";
// import "./jquery2";

// import "./globalcss.css"

function Header ()  {
  
  return (
    <>
    
      <ExternalScripts />
        <div class="reviews-banner">
        <a
          href="https://g.co/kgs/4LJ1YN"
          target="_blank"
          class="reviews-banner-link w-inline-block"
        >
          <div class="reviews-banner-text">
            <strong class="reviews-text">
              ⭐️⭐️⭐️⭐️⭐️ Leave A Review - See What Our Happy Customers Have
              Said
            </strong>
          </div>
        </a>
      </div>
      
      <div id="navbar" class="navbar-container">
      <div class="main-container navbar-inner">
        <div data-collapse="medium" data-animation="default" data-duration="400" data-easing="ease" data-easing2="ease"
          role="banner" class="navbar w-nav">
          <div class="navbar-inner">
            <div class="logo-wrapper"><a  class="brand w-nav-brand"><img
                  src={clogo}
                  width="90"
                  sizes="(max-width: 479px) 50vw, (max-width: 991px) 119.99999237060547px, (max-width: 1439px) 12vw, 119.99999237060547px"
                  alt="cpat logo" /></a></div>
            <nav role="navigation" class="nav-menu w-nav-menu">
              <Link to="/"><a  class="nav-link w-nav-link">Home</a></Link>
             <Link to="/AboutUs">
              <a
                 class="nav-link w-nav-link">About Us</a>
                 </Link>
               <Link to="/">
                <a
                 href="#page-1" 
                 
                class="nav-link w-nav-link ">
                  Services
                  </a>
                  </Link>
              <div  data-hover="true" class="dropdown w-dropdown">
                <div class="nav-link dropdown-link-wrapper w-dropdown-toggle">
                  <div class=" nav-link-inner">Blog</div>
                </div>
                <nav class="dropdown-list w-dropdown-list">
                  <div>
                    <Link to="/WhatIsAutism" class="dropdown-link w-dropdown-link">
                      What Is Autism
                      </Link>
                    <Link to="/WhatIsAbaTherapy" class="dropdown-link w-dropdown-link">
                      What Is Aba Therapy?
                      </Link>
                      <Link to="/WhyAbaEffective" class="dropdown-link w-dropdown-link">
                     
                      Why Aba Effective
                      </Link>
                      
                    <Link to="/BenifitsOfAbaTherapy" class="dropdown-link w-dropdown-link">
                      Benifts Of Aba Therapy
                      </Link>
                      <Link to="/OTvsPT" class="dropdown-link w-dropdown-link">
                       OT vs PT
                      </Link>
                      <Link to="/PhysicalTherapy"  class="dropdown-link w-dropdown-link">
                       PT to Improve Movement
                      </Link>
                   <Link  to="/PtforNeckPain"   class="dropdown-link w-dropdown-link">
                    PT for Neck Pain </Link>
                    <Link to="/ReasonstostartPt"  class="dropdown-link w-dropdown-link">
                     Reasons to start PT
                      </Link>
                     <Link to="/SpeechTherapy"   class="dropdown-link w-dropdown-link">
                    Speech Therapy </Link>
                      <Link to="/SpeechScreenings" class="dropdown-link w-dropdown-link">
                    
                      Speech Screenings
                      </Link>
                      
                 <Link to="/TipsforSpeechTherapySession" class="dropdown-link w-dropdown-link" >
                      Tips for Speech Therapy Session
                      </Link></div>
                </nav>
              </div><a href="tel:+923011605000" class="nav-link w-nav-link">
                Contact Us
                </a>
            </nav>
            <div class="navbar-right">
              <div id="w-node-_78b61904-d512-a758-a5cd-36e2f3008f2a-f3008f07" class="navbar-right-contents">
                <div class="navbar-functions"><a href="tel: +923011605000"
                    class="button phone-link w-inline-block"></a><a href="/contact-us">
                    {/* <div>Get Started</div> */}
                  </a></div>
                <div class="menu-button w-nav-button" ><img
                    
                   src={menuicon}
                    alt="" class="menu-button-image" /><img
                    src={crossmenu}
                    alt="" class="menu-button-close-image" /></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>  
      <br></br> 
        
    </>
  )
}

export default Header