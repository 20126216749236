import React from "react";
import "./tassets/totalcare.css";
import clogo from "../assets/clogo.png";
import mont from "./tassets/mont.jpg";
import melg from "./tassets/melg.jpg";
import mha from "./tassets/mha.jpg";
import msp from "./tassets/msp.jpg";
import mrp from "./tassets/mrp.jpg";
import msgcb from "./tassets/msgcb.jpg";
import ms from "./tassets/ms.jpg";
import Header from "./header";
import Footer from "./footer";
import Fade from "react-reveal/Fade";
import Zoom from "react-reveal/Zoom";
import Page from "./Page";
function Montessori() {
  return (
    <>
    <Page title="Montessori" />
      <Header />
      <div className="servicediv">
      <Fade left delay={700}>
        <h4 className="oth">Montessori</h4>
        <br></br> <br></br>
      </Fade>
      <div class="otit">
        <div className="otpicdiv">
          <Zoom delay={500}>
            <img className="otpic" src={mont} alt="montessori"></img>
          </Zoom>
        </div>
        <div class="text-small ottext ">
          <Fade bottom delay={300}>
            Montessori education is an educational approach developed by Dr.
            Maria Montessori, an Italian physician and educator, in the early
            20th century. It emphasizes self-directed learning, hands-on activities,
             and mixed-age classrooms to foster independence, creativity, and a love for learning in children.
            <br></br>
            
          </Fade>
        </div>
      </div>
      <br></br>
      <div class="section less-padding">
      <div class="container-default">
        <h2 class="title about-story">Our Services in Montessori</h2>
        <div class="services-cards">
          <div class="services-card"><img
              src={melg}
              
              sizes="(max-width: 479px) 100vw, (max-width: 767px) 75vw, (max-width: 991px) 46vw, (max-width: 1439px) 30vw, 380px"
              
              alt="Montessori Early Learning Goals" class="services-card-img" />
            <div class="services-card-content">
              <h2 class="services-card-title">Montessori Early Learning Goals</h2>
              <p class="services-card-subtitle">It emphasize child-centered education, fostering independence, creativity, and self-discipline. It focuses on nurturing a love for learning and developing skills through hands-on, individualized activities.<br /></p>
            </div>
          </div>
          <div class="services-card"><img
              src={mha}
              
              sizes="(max-width: 479px) 100vw, (max-width: 767px) 75vw, (max-width: 991px) 46vw, (max-width: 1439px) 30vw, 380px"
             
              alt="Montessori Hands-on Activities" class="services-card-img" />
            <div class="services-card-content">
              <h2 class="services-card-title">Montessori Hands-on Activities</h2>
              <p class="services-card-subtitle">These are educational experiences that emphasize tactile learning. They engage children in practical, sensorial tasks to foster independence, creativity, and cognitive development.<br /></p>
            </div>
          </div>
          <div class="services-card"><img
              src={msp}
              
              sizes="(max-width: 479px) 100vw, (max-width: 767px) 75vw, (max-width: 991px) 46vw, (max-width: 1439px) 30vw, 380px"
              
              alt="Montessori Sensory Play" class="services-card-img" />
            <div class="services-card-content">
              <h2 class="services-card-title">Montessori Sensory Play</h2>
              <p class="services-card-subtitle">It fosters early childhood development through hands-on activities that engage the senses. It encourages exploration and learning by stimulating touch, sight, smell, and more in a self-directed manner.<br /></p>
            </div>
          </div>
        
          <div class="services-card"><img
              src={mrp}
              
              sizes="(max-width: 479px) 100vw, (max-width: 767px) 75vw, (max-width: 991px) 46vw, (max-width: 1439px) 30vw, 380px"
              
              alt="Montessori Role Play" class="services-card-img" />
            <div class="services-card-content">
              <h2 class="services-card-title">Montessori Role Play</h2>
              <p class="services-card-subtitle">It encourages children to explore and learn through imaginative play. It fosters independence and problem-solving as kids take on various roles and engage in hands-on, self-directed activities.<br /></p>
            </div>
          </div>
          <div class="services-card"><img
              src={ms}
              
              sizes="(max-width: 479px) 100vw, (max-width: 767px) 75vw, (max-width: 991px) 46vw, (max-width: 1439px) 30vw, 380px"
             
              alt="Montessori Sports" class="services-card-img" />
            <div class="services-card-content">
              <h2 class="services-card-title">Montessori Sports</h2>
              <p class="services-card-subtitle">It is an educational approach to physical activity that aligns with the Montessori philosophy, emphasizing self-directed learning, teamwork, and skill development through non-competitive and inclusive sports experiences.<br /></p>
            </div>
          </div>
          <div class="services-card"><img
              src={msgcb}
              
              sizes="(max-width: 479px) 100vw, (max-width: 767px) 75vw, (max-width: 991px) 46vw, (max-width: 1439px) 30vw, 380px"
              
              alt="Montessori Self-Grooming and Confidence Building" class="services-card-img" />
            <div class="services-card-content">
              <h2 class="services-card-title">Montessori Self-Grooming and Confidence Building </h2>
              <p class="services-card-subtitle">It empowers children with essential life skills, fostering independence, self-esteem, and a sense of capability through hands-on activities and positive reinforcement.<br /></p>
            </div>
          </div>
         
        </div>
      </div>
    </div>
      <br></br>
      <br></br>
      <br></br> <br></br>
      <br></br>
      <br></br>
      </div>
      <Footer />
    </>
  );
}

export default Montessori;
