import React from "react";
import "./tassets/totalcare.css";
import clogo from "../assets/clogo.png";
import playandart from "./tassets/playandart.jpg";
import Header from "./header";
import Footer from "./footer";
import Fade from "react-reveal/Fade";
import Zoom from "react-reveal/Zoom";

function Playandart() {
  return (
    <>
      <Header />
      <Fade left delay={700}>
        <h4 className="oth">Play and Art Therapy</h4>
        <br></br> <br></br>
      </Fade>
      <div class="otit">
        <div className="otpicdiv">
          <Zoom delay={500}>
            <img className="otpic" src={playandart}></img>
          </Zoom>
        </div>
        <div class="text-small ottext ">
          <Fade bottom delay={300}>
          Play therapy and art therapy are two therapeutic approaches that utilize play and creative expression as means of facilitating healing, growth, and self-expression. While they share some similarities, they differ in their primary modes of expression and techniques used.
            <br></br>
           
            <b>1. Play Therapy: </b>Play therapy is a form of therapy primarily designed for children, although it can also be used with adolescents and adults. It recognizes that play is a natural and essential way for children to communicate and process their emotions and experiences. The play therapy process involves a trained therapist engaging with the child in a playroom that contains a wide range of toys and materials.
            <br></br>
            The therapist creates a safe and accepting environment where the child can freely express their thoughts, feelings, and experiences through play. Through play, children can act out scenarios, express their emotions, explore difficult situations, and develop problem-solving skills. The therapist may observe and participate in the play, offer reflective comments, ask open-ended questions, and provide support and guidance as needed. Play therapy can help children address a variety of emotional and behavioral issues, such as anxiety, trauma, grief, social difficulties, and developmental challenges.
            
            <br></br>
          </Fade>
            
        </div>
      </div>
      <br></br>
      <div class="text-small ottext2 ">
        <Zoom>
        <b> 2. Art Therapy:</b>
            Art therapy is a form of therapy that uses various art materials and creative processes to promote self-expression, personal insight, and healing. It can be used with individuals of all ages, including children, adolescents, and adults. Art therapists are trained professionals who guide clients through the creative process and facilitate exploration and understanding of their thoughts, emotions, and experiences.
          <br></br>
          In art therapy, clients are encouraged to engage in art-making, such as drawing, painting, sculpting, and collage. The emphasis is not on creating aesthetically pleasing artwork but rather on the process of creation and self-expression. Art can serve as a medium for exploring unconscious feelings, resolving conflicts, increasing self-awareness, and fostering personal growth. Art therapists may interpret and discuss the client's artwork with them, helping them gain insights into their emotions, thoughts, and behavioral patterns. Art therapy can be effective in addressing a wide range of mental health concerns, including trauma, depression, anxiety, self-esteem issues, and addiction.
<br></br>
Both play therapy and art therapy provide clients with a non-verbal means of expression, which can be particularly beneficial for individuals who may struggle with verbal communication or have difficulty accessing and expressing their emotions. These therapeutic approaches aim to create a safe and supportive environment where clients can freely explore their inner worlds, develop coping skills, and work towards positive change.
        
        </Zoom>
        
      </div>
      <br></br>
      <br></br>
      <br></br> <br></br>
      <br></br>
      <br></br>
      <Footer />
    </>
  );
}

export default Playandart;
