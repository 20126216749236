import React, { useEffect, useState } from 'react';

const ExternalScripts = () => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (!isLoaded) {
      // Load the jQuery script
      const script1 = document.createElement('script');
      script1.src = 'https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=63e413c214acc42465a98a2a';
      script1.type = 'text/javascript';
      script1.integrity = 'sha256-9/aliU8dGd2tb6OSsuzixeV4y/faTqgFtohetphbbj0=';
      script1.crossOrigin = 'anonymous';

      // Load the Webflow script
      const script2 = document.createElement('script');
      script2.src = 'https://global-uploads.webflow.com/63e413c214acc42465a98a2a/js/webflow.be8a8fcee.js';
      script2.type = 'text/javascript';

      // Append the script elements to the document
      document.body.appendChild(script1);
      script1.onload = () => {
        document.body.appendChild(script2);
        setIsLoaded(true);
      };

      // Set up a global error handler
      window.onerror = (message, source, lineno, colno, error) => {
        // Log the error message to the console
        console.error('Global error handler:', message);
      };

      return () => {
        // Remove the global error handler when the component unmounts
        window.onerror = null;

        // Check if the script elements are still attached to the DOM before removal
        if (document.body.contains(script1)) {
          document.body.removeChild(script1);
        }
        if (document.body.contains(script2)) {
          document.body.removeChild(script2);
        }
      };
    }
  }, [isLoaded]);

  return null; // This component doesn't render any visible content
};

export default ExternalScripts;
