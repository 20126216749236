import React from "react";
import Page from "./Page";
import Header from "./header";
import Footer from "./footer";

function ReasonstostartPt() {
  return (
    <>
        <Page title="Reasons to start PT" />

      <Header />
      <div class="section-3 blog-post header">
        <div class="blog-post-image-overlay"></div>
        <div class="container-large-1016px blog-post hero-blog-post">
          <h1 class="display-heading article-title">
            Reasons to start or continue physical therapy before the end of the
            year
          </h1>

          <div class="blog-post-about-wrapper">
            <div class="blog-post-about-author-wrapper">
              <div class="blog-post-about-author-content">
                <div class="blog-post-about-author-name">
                  <strong>Posted 12/8/2022 | 4 min. read</strong>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="section blog-post">
        <div class="container-large-1016px blog-post">
          <div class="rich-text w-richtext">
            <p>
              If you’ve never been to a physical therapist, you may not know
              some of it’s surprising benefits. And if you have needed physical
              therapy (also known as PT) at some point, this article may still
              hold some surprising considerations. But first things first. Like
              the not so subtle calendar to the right. Yes, it’s that time
              again, when the old year is about ready to phase into a new one.
              For many of our readers, the little red circle at the end of the
              month sounds the alarm for just-oh-how-much still needs to get
              done. But give us a few minutes and we’ll give you five reasons
              for the importance – and benefits – of fitting physical therapy
              into your end-of-the-year routine to jump start your healthy new
              year.
            </p>

            <h3>Reason 1</h3>
            <p>
              Take a minute to assess this past year. If there’s one fact that
              we share it’s that we all got older. Now, we’re not asking you to
              share your age, but just think about how you’re feeling overall.
              Tired? A bit run-down? Less energy, perhaps, or maybe just not
              feeling as healthy as you did at the beginning of the year. As we
              age, we lose muscle mass and endurance. You may notice new aches
              and pains or popping – crunchy – noises in your joints. Physical
              therapy can address all these conditions, and our therapists (aka
              movement experts) can help with joint pain, arthritis, balance
              issues and teaching you how to make simple adjustments to your
              everyday movements to feel better and help prevent aches or an
              injury. Physical therapy can be a great way to give your body a
              tune-up and move more easily.
            </p>
            <h3>Reason 2</h3>
            <strong>Stress</strong>
            <p>
              It’s a big enough reason that there are organizations dedicated to
              its study. A 2022 “Stress in America” study shows the worsening
              effects of stress in the U.S. The end-of-the-year holidays can
              increase stress and anxiety. Many of our patients come to us with
              tension in the neck, shoulders and back. These are three main
              areas where stress can set in and have you feeling unwell.
              Physical therapy, especially manual (hands-on) therapy and
              instrument-guided soft tissue mobilization therapy, can help
              relieve stress by relaxing muscles and pinpointing treatment to
              the connective tissue around the muscles. Our therapists can also
              give tips for exercise and simple stretches that can do wonders
              for reducing stress.
            </p>
            <h3>Reason 3</h3>
            <strong>Prehab</strong>
            <p>
              Never heard about prehab? Well, then this may just surprise you.
              If you’re headed for surgery in the New Year, pre-surgical
              physical therapy can be a helpful way to prepare now (the “pre”),
              and help you recover more quickly afterward (the “rehab”). We see
              many good results using this approach with joint replacement
              surgery. So good, that we wrote a blog that includes the benefits
              of medical rehabilitation when combining physical therapy before
              and after surgery. Post-operative studies show prehab leads to
              better outcomes, including:
            </p>
            <ul role="list">
              <li>Fewer days in the hospital</li>
              <li>More stamina for physical exercise after discharge</li>
              <li>
                Getting familiar with therapy equipment – before surgery – that
                will be used in your rehabilitation
              </li>
            </ul>
            <h3>Reason 4</h3>
            <p>
              Injury prevention is often a less talked about benefit of physical
              therapy. But we place just as much value on learning how to help
              prevent injury as on how to recover from injury. Depending on
              where you live, the change in weather can mean more cold, snow and
              ice. And that can mean more slips, trips and falls. But slips,
              trips and falls can happen in the home too, especially if you’re
              at a higher risk for falling due to balance issues, like vertigo
              or dizziness. Or maybe you need help learning to use a new
              assistive device safely, like a walker. Physical therapists are
              familiar with body anatomy and how the body moves – it’s why we
              call them movement experts. Their knowledge of a wide range of
              body parts makes them an excellent source for helping you learn
              exercises to strengthen and stabilize your muscles. These
              exercises are meant to help you stay safe and reduce the risk of
              falling. For an athlete, sports injury prevention is important,
              and physical therapy can help with that too. Our physical
              therapists understand the risks in playing a sport and ways to
              reduce the most common types of injuries, like stress fractures,
              ACL tears and concussion.
            </p>

            <p>
              Reference:{" "}
              <a href="https://www.kesslerrehabilitationcenter.com/why-choose-us/blog/5-great-reasons-to-start-or-continue-physical-therapy-before-the-end-of-the-year/"
              target="_blank">
                {" "}
                Kessler Rehabilitation Center{" "}
              </a>
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ReasonstostartPt;
