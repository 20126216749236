import React from "react";
import Page from "./Page";
import Header from "./header";
import Footer from "./footer";

function PhysicalTherapy() {
  return (
    <>
        <Page title="PT to Improve Movement" />

      <Header />
      <div class="section-3 blog-post header">
        <div class="blog-post-image-overlay"></div>
        <div class="container-large-1016px blog-post hero-blog-post">
          <h1 class="display-heading article-title">
          Physical Therapy: How Non-Traditional Treatments Can Help Improve Movement
          </h1>

          <div class="blog-post-about-wrapper">
            <div class="blog-post-about-author-wrapper">
              <div class="blog-post-about-author-content">
                <div class="blog-post-about-author-name">
                  <strong>Posted: 1/12/2023 | 4 min. read</strong>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="section blog-post">
        <div class="container-large-1016px blog-post">
          <div class="rich-text w-richtext">
            <p>
            You may think about physical therapy as a way to get back on your feet after an injury or regain strength after surgery.

And you would be right.

A typical idea of what physical therapy looks like might include a room with padded tables, balance balls and exercise equipment. These are traditional items well-known to those working through movement issues with the professional help and guidance of a physical therapist.

Treatments using these items are pretty standard for improving strength and restoring movement.

But did you know there are other types of treatments – and equipment – that while maybe not be as common are also effective in improving movement?

As a movement expert, your physical therapist can evaluate the type of treatment or treatments that can benefit your condition. Chances are, there may be a mix of traditional and not-so-traditional treatments to:
            </p>
            <ul role="list">
              <li>Increase your range of motion</li>
              <li>Decrease pain and discomfort</li>
              <li>Build endurance</li>
              <li>Lower or avoid the need for medication</li>
              <li>Recover quality of life</li>

            </ul>
            <br/>
            <p>Depending on your condition or diagnosis, the success of some less familiar physical therapy treatments can make them a consideration for your care plan.</p>
            <h2>Cupping</h2>
            <p>
            Cupping treats muscle and soft tissue dysfunction, like spasms, swelling and pain.

This manual technique uses small silicone “cups” or “domes” (sometimes glass) pressed against the flesh to create suction.

The technique stimulates blood flow as the suction pulls the skin upward into the cup. This expands tiny blood vessels under the skin to increase blood flow, turning the cupped area a reddish or pink color.
            </p>
<p>Cupping has been around for thousands of years, but is one of the lesser known techniques employed to ease:</p>
            <ul role="list">
              <li>Back and neck pain</li>
              <li>Headache relief</li>
              <li>Arthritis</li>
        
            </ul>
<h2>Dry Needling</h2>
            <p>
            Much different than cupping, which targets broader areas of the body, dry needling is a micro treatment.

As the name implies, dry needling uses thin needles inserted into the skin at precise points in the muscle where there is pain or sensitivity. These points in the muscle, when touched or stimulated, can trigger pain, which is why this treatment is also known as “trigger point dry needling.” There is no medication delivered through the needle, like with a vaccine, which is why it’s called a dry needle.

When inserted into the skin, the needle shuts down the trigger point, easing or eliminating the pain.
            </p>
<p>Though it uses needles, this alternative method of pain relief is not acupuncture, a common misconception.

Dry needling is a technique for stimulating and releasing trigger points, while acupuncture is a practice that’s focused on restoring energy flow in the body.

In the dry needle procedure, the length of the needle will depend on the area of the body being treated. Most people feel little or no pain, and the procedure lasts about 15 minutes.

If appropriate for your type of pain, our therapists will create a treatment plan that includes dry needling along with other therapies.

Dry needling is not a service provided in every state. Find a location near you that provides dry needling services.</p>
   
   <h2>Instrument-assisted soft tissue mobilization (IASTM)</h2>
   <p>Ever had a deep tissue massage? That firm pressure targeting a deep ache or an area of chronic pain?

Ahhh… right?

Well, IASTM is a technique that gets deep into the tissues, including the muscles.

Similar to deep tissue massage, soft tissue mobilization works like standard massage, but using specialized instruments. Used quite often in physical therapy, it allows for more direct treatment than just using the hands.

IASTM produces good results for large muscle groups as well as smaller, targeted areas.

Angle and pressure in using the instruments help your therapist work on a specific layer of soft tissue during treatment. Often, these are areas where prior injury or long-term overuse has contributed to a build-up of scar tissue that IASTM can help break down.</p>
  
  <p>IASTM is a good choice for many who have soft tissue pain or injury contributing to movement restriction, including:</p>


            <ul role="list">
              <li>
              Arthritis sufferers
              </li>
              <li>
              Athletes
              </li>
              <li>
              Workers who perform long-term repetitive tasks or whose job has them on their feet a lot
              </li>
            </ul>
            <br/>
            <p>
              Reference:{" "}
              <a href="https://www.kesslerrehabilitationcenter.com/why-choose-us/blog/pt-how-non-traditional-treatments-can-help-improve-movement/"
              target="_blank">
                {" "}
                Kessler Rehabilitation Center{" "}
              </a>
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default PhysicalTherapy;
