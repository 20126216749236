import React from "react";
import "./tassets/totalcare.css";
import clogo from "../assets/clogo.png";
import pt from "./tassets/pt.jpg";
import te from "./tassets/te.jpg";
import mt from "./tassets/mt.jpg";
import mpa from "./tassets/mpa.jpg";
import pme from "./tassets/pme.png";
import Page from "./Page";
import Header from "./header";
import Footer from "./footer";
import Fade from "react-reveal/Fade";
import Zoom from "react-reveal/Zoom";

function Pt() {
  return (
    <>
        <Page title="Physiotherapy" />

      <Header />
      <div className="servicediv">
      <Fade left delay={700}>
        <h4 className="oth">Physiotherapy</h4>
        <br></br> <br></br>
      </Fade>
      <div class="otit">
        <div className="otpicdiv">
          <Zoom delay={500}>
            <img className="otpic" src={pt} alt="physiotherapy"></img>
          </Zoom>
        </div>
        <div class="text-small ottext ">
          <Fade bottom delay={300}>
          Physiotherapy, often referred to as physical therapy, is a healthcare profession focused on enhancing and restoring physical function and mobility in individuals. It involves the use of various techniques, exercises, and manual therapies to address injuries, disabilities, and health conditions. Physiotherapists assess, diagnose, and treat patients to alleviate pain, improve muscle strength, flexibility, and overall well-being. This practice plays a crucial role in rehabilitation, sports medicine, and the management of chronic conditions.
            <br></br>
           
          </Fade>
        </div>
      </div>
      <br></br>
      <div class="section less-padding">
      <div class="container-default">
        <h2 class="title about-story">Our Services in Physiotherapy</h2>
        <div class="services-cards">
          <div class="services-card"><img
              src={te}
              
              sizes="(max-width: 479px) 100vw, (max-width: 767px) 75vw, (max-width: 991px) 46vw, (max-width: 1439px) 30vw, 380px"
              
              alt="Therapeutic Exercises" class="services-card-img" />
            <div class="services-card-content">
              <h2 class="services-card-title">Therapeutic Exercises</h2>
              <p class="services-card-subtitle">These are physical activities designed to improve or restore physical function and reduce pain. 
              They are often prescribed by healthcare professionals to address various conditions or injuries, such as musculoskeletal problems, cardiovascular issues, or neurological disorders through different exercises.  <br /></p>
            </div>
          </div>
          <div class="services-card"><img
              src={mt}
              
              sizes="(max-width: 479px) 100vw, (max-width: 767px) 75vw, (max-width: 991px) 46vw, (max-width: 1439px) 30vw, 380px"
             
              alt="Manual Therapy" class="services-card-img" />
            <div class="services-card-content">
              <h2 class="services-card-title">Manual Therapy</h2>
              <p class="services-card-subtitle">It is a hands-on approach to healthcare, utilizing skilled techniques to diagnose, treat, and prevent musculoskeletal conditions. It includes manipulation, mobilization, and soft tissue techniques for pain relief and improved mobility.<br /></p>
            </div>
          </div>
          <div class="services-card"><img
              src={mpa}
              
              sizes="(max-width: 479px) 100vw, (max-width: 767px) 75vw, (max-width: 991px) 46vw, (max-width: 1439px) 30vw, 380px"
              
              alt="Modalities and Physical Agents" class="services-card-img" />
            <div class="services-card-content">
              <h2 class="services-card-title">Modalities and Physical Agents</h2>
              <p class="services-card-subtitle">It is encompassing a variety of therapeutic tools like heat, cold, ultrasound, and electrical stimulation used in healthcare to alleviate pain, promote healing, and enhance rehabilitation.<br /></p>
            </div>
          </div>
          <div class="services-card gam"><img
              src={pme}
              
              sizes="(max-width: 479px) 100vw, (max-width: 767px) 75vw, (max-width: 991px) 46vw, (max-width: 1439px) 30vw, 380px"
              
              alt="Postural Management and Ergonomics" class="services-card-img" />
            <div class="services-card-content">
              <h2 class="services-card-title">Postural Management and Ergonomics</h2>
              <p class="services-card-subtitle">It’s aim to optimize body alignment during daily activities to prevent discomfort and injury. They involve proper positioning, equipment setup, and awareness for improved well-being.<br /></p>
            </div>
          </div>
         
        </div>
      </div>
      </div>
      <br></br>
      <br></br>
      <br></br> <br></br>
      <br></br>
      <br></br>
      </div>
      <Footer />
    </>
  );
}

export default Pt;
