import React from 'react'
import "./tassets/totalcare.css";
import Header from "./header";
import Footer from "./footer";
import Page from './Page';
function WhyAbaEffective  ()  {
  return (
    <>
        <Page title="What Aba Effective" />

    <Header/>
    <div
       class="section-3 blog-post header">
      <div class="blog-post-image-overlay"></div>
      <div class="container-large-1016px blog-post hero-blog-post">
        <h1 class="display-heading article-title">Why ABA Therapy Is Effective</h1>
        <p class="text-large article-subtitle">Today we&#x27;ll show you why ABA therapy is effective in helping kids
          with autism thrive on the spectrum.</p>
        <div class="blog-post-about-wrapper">
          <div class="blog-post-about-author-wrapper">
            <div class="image-wrapper blog-post-about-author"><img
                src="https://global-uploads.webflow.com/63e413c214acc42465a98a2a/63e413c214acc465fba98b83_ruben.jpeg"
                alt="reuben kesherim" class="image-2 blog-post-about-author" /></div>
            <div class="blog-post-about-author-content"><a href="https://www.totalcareaba.com/team/ruben-kesherim"
                target="_blank" class="blog-post-about-author-link w-inline-block">
                <div class="blog-post-about-author-name"><strong>Ruben Kesherim</strong></div>
              </a>
              <div class="blog-post-about-date">June 23, 2023</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section blog-post">
      <div class="container-large-1016px blog-post">
        <div class="rich-text w-richtext">
          <h2>Find out why ABA therapy is effective for helping kids with autism reduce negative behaviors and increase
            positive ones.</h2>
          <p>As a parent, if you’ve ever questioned the effectiveness of ABA therapy, let the evidence speak for itself.
            In 1987, the grandfather of Assisted Behavior Analysis (ABA) therapy Dr. Ivar Lovaas proved that early
            intervention and intensive behavioral therapy enabled children with Autism to achieve success.</p>
          <p>He concluded that <a
              href="https://childmind.org/article/controversy-around-applied-behavior-analysis/#:~:text=Studies%20have%20shown%20that%20ABA,because%20it%20is%20so%20repetitive."
              target="_blank">90%</a> of children make substantial gains through ABA therapy (Lovaas, O. I. 55: 3-9).
            Lovaas added that 47% of the children studied during his research were “indistinguishable from their peers”
            after receiving intensive ABA therapy (Lovaas, O. I. 1987).</p>
          <p>Since then, parents and therapists alike have seen first-hand how ABA is effective at reducing disruptive
            behaviors including tantrums, aggression, inappropriate self-stimulatory behavior, and <a
              href="https://www.sbsaba.com/techniques-to-stop-self-injurious-behavior/">self-injury</a>.</p>
          {/* <figure style="max-width:1124px" class="w-richtext-align-fullwidth w-richtext-figure-type-image">
            <div><img
                src="https://global-uploads.webflow.com/63e413c214acc4a298a98a44/642b7ae804ea375aed5bc484_pexels-photo-8654129.jpeg"
                alt="Free Bored Boy Holding Colored Pencils Stock Photo" /></div>
          </figure> */}
          <p>Additionally, <a href="https://www.goldenstepsaba.com/resources/what-is-aba-therapy-how-does-it-work/"
              target="_blank">ABA therapy</a> is effective at increasing the social skills that many children with
            Autism find challenging such as social engagement, communication, play, and self-help skills. The power of
            ABA is in its evidence-based results. However, despite all the data and testimony regarding its
            effectiveness, there are still skeptics who remain unconvinced.</p>
          <h2><strong>Why is ABA an Effective Treatment for Autism?</strong></h2>
          <p>The tenets of <a href="https://www.supportivecareaba.com/aba-therapy/what-is-aba-therapy"
              target="_blank">ABA therapy</a> state that a clinician must create an individualized program designed
            specifically for each of their clients using a variety of behavioral strategies. Together the clinician and
            the parent outline areas of <a href="https://www.discoveryaba.com/aba-therapy/importance"
              target="_blank">importance</a> and then focus on improving those skills within the child.</p>
          <p>The benefit of the <a href="https://www.ambitionsaba.com/resources/what-is-applied-behavior-analysis"
              target="_blank">ABA therapy</a> approach is that it breaks down larger skills into small manageable steps.
            Then, by using the power of reinforcement, the child masters each of those steps. </p>
          <p>While the ABA therapist works with your child data is collected throughout each session. This continuous
            monitoring of their progress allows the therapy team to see what approaches are succeeding with your child
            and what others aren’t thereby prompting them to make changes to ensure continued success.</p>
          <h2><strong>How Much ABA Therapy is Needed?</strong></h2>
          <p>The success of ABA therapy relies on repetition in order for a child with Autism to learn and demonstrate
            new skills. Research has shown that 40 hours of therapy for a child is a crucial number in order to see
            significant improvement. </p>
          <blockquote>That being said, studies have shown that 20-40 hours of therapy can be optimal for a child needing
            comprehensive intervention. </blockquote>
          <p>If there are only a few specific skills or deficits that need to be worked on 10-20 hours may be effective.
          </p>
          <p>Often young children will start with a full-time comprehensive therapy program and as they make progress
            the hours will be reduced. After a full evaluation with your child’s therapy team, suggestions will be made
            on what intensity of ABA therapy is best suited to them.</p>
          <h2><strong>Comparing ABA to other Interventions</strong></h2>
          <p>Today, there are as many choices in therapy as there are degrees of Autism. For parents, sometimes these
            options can be overwhelming. Despite the variety, when parents compare different therapies, the overwhelming
            evidence is almost always in favor of ABA.</p>
          <p>For example, the <a href="http://asatonline.org/intervention/recommendations.htm">Association for Science
              in Autism (ASAT)</a>, states:</p>
          <p><em>Because ABA currently has substantially more scientific support than any other behavioral or
              educational intervention for children with ASD, ASAT recommends that families and professionals strongly
              consider implementing ABA and be cautious about other approaches. ASAT further recommends that
              professionals describe other behavioral and educational interventions as untested and encourage families
              who are considering these interventions to evaluate them carefully.</em></p>
          <p>Further to that, the <a href="https://surgeongeneral.gov/library/mentalhealth/chapter3/sec6.html">United
              States Surgeon General</a>, states:</p>
          <p><em>Thirty years of research demonstrated the efficacy of applied behavioral methods (ABA) in reducing
              inappropriate behavior and in increasing communication, learning and appropriate social behavior.</em></p>
          <h2><strong>Efficacy</strong></h2>
          <p>What was said 30 years ago by the grandfather of ABA therapy Dr. Ivar Lovaas remains true today. Early
            intervention coupled with intensive therapy enables children with Autism to achieve success. </p>
          <p>Despite Dr. Lovaas’s scientific proof, some parents with a newly diagnosed child with Autism may not be
            convinced of ABA’s effectiveness. If that is the case then the following quote from Dr. Mary Beth Walsh
            whose son is a child with Autism will undoubtedly silence those doubters:</p>
          <p>Every child deserves this chance to show others all that he or she is capable of; every child deserves to
            learn all he can learn; every child with autism deserves effective, behavioral intervention, and it is up to
            us parents to make sure our children with autism get what they deserve.</p>
        </div>
        <p>
              Reference:{" "}
              <a href="https://www.totalcareaba.com/autism/is-aba-therapy-effective"
             target="_blank" >
                {" "}
                Totalcare ABA{" "}
              </a>
            </p>
      </div>
      </div>
    <Footer/>
    </>
  )
}

export default WhyAbaEffective